.imageblock {
  &.wrap-right {
    width: 40%;
    float: right;
    margin: 30px 0% 30px 30px;
  }

  &.wrap-left {
    width: 40%;
    float: left;
    margin: 30px 30px 30px 0%;
  }

  &.full {
    margin: 30px 0% 30px 0%;
  }
}

.imageblock-caption {
  text-align: left;
  padding: 0 10px;
  margin: rem(10) 0 0;
  @include font(14);
  @extend .ts-italic;
}

@include media($tablet) {

}

@include media($mobile) {
  .imageblock {
    &.wrap-right,
    &.wrap-left,
    &.full {
      width: 100%;
      float: none;
      display: block;
      margin: 30px 0;
      padding: 0 15px;
    }
  }
}
