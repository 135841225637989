.nav {
  margin: 0;
  padding: 0;
  display: inline-block;
  line-height: 0;
}

.nav-list {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.nav-item {
  margin: 0;
  padding: 0;
  float: left;
  display: block;
  vertical-align: middle;
}

.nav-link {
  margin: 0;
  padding: 0;
  display: block;
  @include font(15, 400, 1);
  @include transition(all 0.25s $ease-in-out-quad);
}

.nav--topbar {
  float: right;
  padding: rem(10) 0;

  .nav-item {
    padding: 0;

    + .nav-item {
      margin-left: 35px;
    }
  }

  .nav-link {
    @include font(16, 600, 1);
    color: $nav--topbar-item-color;

    &:hover {
      color: $nav--topbar-item-color--hover;
    }
  }

  .nav-link--action {
    margin: rem(-15) 0;
    padding: rem(15) rem(20) rem(13);
    background: $black-off;
    color: $white;
  }
}

.nav--user {
  float: right;

  .nav-item {
    padding: 0 rem(10);
  }

  .nav-link {
    @include font(12, 400, 1);
    color: $nav--topbar-item-color;

    &:hover {
      color: $nav--topbar-item-color--hover;
    }
  }
}

.nav--side {
  display: block;
  margin: 0 0 rem(30);
  padding: 0;

  .nav-heading {
    padding: 0 rem(5);
  }

  .nav-list {
    border-top: 1px solid $grey-light;
    display: block;
  }

  .nav-item {
    border-bottom: 1px solid $grey-light;
    float: none;
    display: block;
    padding: 0;
  }

  .nav-link {
    padding: rem(15) rem(5);
    display: block;
    @include font(17, 400, 1);
  }
}

.nav--footer {
  .nav-item {
    margin: 0 0 rem(10);
    padding: 0;
    display: block;
    float: none
  }

  .nav-link {
    @include font(15, 400, 1);
  }
}


.nav-item--dropdown {
  position: relative;

  .nav-link {
    padding-right: 10px;
    position: relative;
    @include transition(all 0.25s $ease-in-out-quad);

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 3px;
      right: 0;
      height: 5px;
      border-right: 1px solid $grey;
    }

    &:before {
      @include transform(translateX(-3px) rotate(-45deg));
    }
    &:after {
      @include transform(rotate(45deg));
    }
  }

  &:hover {
    .nav-menu {
      display: block;
      @include transition(all 0.15s $ease-in-out-quad 0.1s);
      @include transform(translateY(0));
      opacity: 1;
      pointer-events: all;
    }
  }
}


.nav-menu {
  position: absolute;
  top: 22px;
  left: 0;
  background: $white;
  padding: 11px;
  opacity: 0;
  border-radius: 3px;
  @include transition(all 0.25s $ease-in-out-quad);
  @include transform(translateY(-10px) scale(0.75));
  @include transform-origin(20px top);
  pointer-events: none;
  box-shadow: 0 1px 2px $base-shadow;
  z-index: $z-dropdown-menu;

  &:before {
    content: "";
    position: absolute;
    top: -8px;
    left: 15px;
    @include triangle(16px, rgba($black,0.1), up);
  }

  &:after {
    content: "";
    position: absolute;
    top: -7px;
    left: 15px;
    @include triangle(16px, white, up);
  }
}

.nav-menu--right {
  left: auto;
  right: 0;
  @include transform-origin(80% top);

  &:before,
  &:after {
    left: auto;
    right: 15px;
  }
}

.nav-menucatch {
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 10px;
}

.nav-br {
  margin: rem(10) 0;
  border-top: 1px solid $grey-light;
}

.nav-menuitem {
  margin: 0;
  padding: 8px 10px;
  display: block;
  @include font(15, 400, 1);
  text-decoration: none;
  color: $dropdown-item-color;
  white-space: nowrap;
  @include transition(all 0.25s $ease-in-out-quad);
  text-align: left;

  &:hover {
    color: $dropdown-item-color--hover;
  }
}

.nav-menuitem--secondary {
  @include font(13, 400, 1);
  padding: 6px 10px;
}

@include media($tablet) {

}

@include media($mobile) {
  .nav--topbar {
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: $white;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    @include transition(0.35s ease);
    height: 0vh;

    .nav-list {
      height: 100%;
      @include display(flex);
      @include flex-direction(column);
    }

    .nav-item {
      @include display(flex);
      @include flex(1);
      @include align-items(center);
    }

    .nav-link {
      width: 100%;
      display: block;
    }

    .nav-item + .nav-item {
      margin: 0;
    }

    .nav-link {
      padding: rem(15) 0;
    }
  }

  .is-mobileOpen {
    .nav--topbar {
      @include calc(height, "100vh - 65px");
    }
  }
}
