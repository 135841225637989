.imagerow {
  margin: 80px 0;

  + .imagerow {
    margin-top: -80px;
  }
}

.imagerow--small {
  overflow: hidden;

  .imagerow-row {
    margin: 0 -3px;
  }

  .imagerow-image {
    border: 3px solid transparent;
  }
}

.imagerow--large {
  overflow: hidden;

  .imagerow-row {
    margin: 0 -15px;
  }

  .imagerow-image {
    border: 15px solid transparent;
  }
}

.imagerow-row {
  display: flex;
  justify-content: space-between;
}

.imagerow-image {
  flex: auto;

  img {
    display: block;
    max-width: none;
    width: 100%;
  }
}

@include media($tablet) {
  .imagerow {
    margin: 40px 0;

    + .imagerow {
      margin-top: -40px;
    }
  }
}

@include media($mobile) {
  .imagerow {
    margin: 20px 0;

    + .imagerow {
      margin-top: -20px;
    }
  }

  .imagerow-row {
    flex-direction: column;
  }
}
