.btn-container {
  padding: rem(20) 0;
}

.btn {
  display: inline-block;
  text-align: center;
  background-color: $btn-bg;
  border: 1px solid $btn-border;
  border-radius: 3px;
  cursor: pointer;
  color: $btn-color;
  outline: none;
  @include transition(border 0.35s, background 0.35s, color 0.35s, opacity 0.35s);
  padding: 9px 25px;
  @include font(16, 600, 1, $base-font-btn);
  text-transform: capitalize;

  &:hover {
    color: $btn-color--hover;
    background-color: $btn-bg--hover;
    border-color: $btn-border--hover;
  }

  &:active {
    opacity: 0.75;
  }
}

.btn-bar {
  margin: rem(8) 0;
}

.btn--blue,
.btn--primary {
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;
  color: $btn-primary-color;

  &:hover {
    color: $btn-primary-color--hover;
    background-color: $btn-primary-bg--hover;
    border-color: $btn-primary-border--hover;
  }

  &.btn--fill {
    color: $btn-primary-color--fill;
    background-color: $btn-primary-bg--fill;

    &:hover {
      background-color: $btn-primary-bg--fill--hover;
      border-color: $btn-primary-border--fill--hover;
    }
  }
}

.btn--white {
  background-color: $btn-primary-bg;
  border-color: $white;
  color: $white;

  &:hover {
    color: $btn-primary-color;
    background-color: $white;
    border-color: $white;
  }

  &.btn--fill {
    color: $btn-primary-bg--fill;
    background-color: $btn-primary-color--fill;

    &:hover {
      background-color: $btn-primary-bg;
      border-color: $white;
      color: $white;
    }
  }
}

.btn--green,
.btn-secondary {
  background-color: $btn-secondary-bg;
  border-color: $btn-secondary-border;
  color: $btn-secondary-color;

  &:hover {
    color: $btn-secondary-color--hover;
    background-color: $btn-secondary-bg--hover;
    border-color: $btn-secondary-border--hover;
  }

  &.btn--fill {
    color: $btn-secondary-color--fill;
    background-color: $btn-secondary-bg--fill;

    &:hover {
      background-color: $btn-secondary-bg--fill--hover;
      border-color: $btn-secondary-border--fill--hover;
    }
  }
}

.btn--landingPage {
  text-shadow: 0 1px 1px rgba($black, 0.1);
  box-shadow: 0 1px 1px rgba($black, 0.1);
  padding: rem(13) rem(37.5) rem(13);
  vertical-align: top;
}

.btn--square {
  border-radius: 0;
}

.btn-danger {
  background-color: $btn-danger-bg;
  border-color: $btn-danger-border;
  color: $btn-danger-color;

  &:hover {
    color: $btn-danger-color--hover;
    background-color: $btn-danger-bg--hover;
    border-color: $btn-danger-border--hover;
  }

  &.btn--fill {
    color: $btn-danger-color--fill;
    background-color: $btn-danger-bg--fill;

    &:hover {
      background-color: $btn-danger-bg--fill--hover;
      border-color: $btn-danger-border--fill--hover;
    }
  }
}

.btn-grey {
  background-color: $btn-grey-bg;
  border-color: $btn-grey-border;
  color: $btn-grey-color;

  &:hover {
    color: $btn-grey-color--hover;
    background-color: $btn-grey-bg--hover;
    border-color: $btn-grey-border--hover;
  }

  &.btn--fill {
    color: $btn-grey-color--fill;
    background-color: $btn-grey-bg--fill;

    &:hover {
      background-color: $btn-grey-bg--fill--hover;
      border-color: $btn-grey-border--fill--hover;
    }
  }
}

.btn-white {
  background-color: $btn-white-bg;
  border-color: $btn-white-border;
  color: $btn-white-color;

  &:hover {
    color: $btn-white-color--hover;
    background-color: $btn-white-bg--hover;
    border-color: $btn-white-border--hover;
  }

  &.btn--fill {
    color: $btn-white-color--fill;
    background-color: $btn-white-bg--fill;

    &:hover {
      background-color: $btn-white-bg--fill--hover;
      border-color: $btn-white-border--fill--hover;
    }
  }
}

.btn-ghost {
  border-color: transparent;
}

.btn--small {
  padding: 7px 15px;
  border-width: 1px;
  @include font(15, 600, 1, $base-font-btn);
}

.btn--medium {
  padding: 9px 20px;
  border-width: 1px;
  @include font(14, 600, 1, $base-font-btn);

  .btn-icon {
    width: 14px;
    height: 14px;
    margin: 0 5px 0 -8px;
  }
}

.btn--large {
  padding: 12px 30px;
  @include font(18, 600, 1, $base-font-btn);

  .btn-icon {
    width: 24px;
    height: 24px;
    margin: -10px 8px -7px -10px;
  }
}

.btn--xlarge {
  padding: 13px 34px;
  @include font(18, 600, 1, $base-font-btn);
}

.btn--full {
  clear: both;
  display: block;
  width: 100%;
}

@include media($tablet) {

}

@include media($mobile) {

}
