%svg-backgrounds {
  width: 600px;
  height: 530px;
  float: left;
  background: url(../svgs/backgrounds-css.svg) no-repeat;
}

.background{
  @extend %svg-backgrounds;
}

.background--black{
  background-color: #333;
  margin-bottom: 250px;
}

.background--1 { background-position: 0 0; }
.background--2 { background-position: 100% 0; }
.background--3 { background-position: 0 100%; }
