.accessibility-dims {
  width: 48px;
  height: 48px;
}

.account-balance-dims {
  width: 48px;
  height: 48px;
}

.account-balance-wallet-dims {
  width: 48px;
  height: 48px;
}

.account-box-dims {
  width: 48px;
  height: 48px;
}

.account-circle-dims {
  width: 48px;
  height: 48px;
}

.add-shopping-cart-dims {
  width: 48px;
  height: 48px;
}

.rotation-3D-dims {
  width: 48px;
  height: 48px;
}


