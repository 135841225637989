.content {
  position: relative;
  padding: $gutter*2 0;
}


.content--page {
  margin-top: 80vh;
  z-index: $z-content--page;
  padding: 0;

  .content-container {
    background: $white;
    padding-top: $gutter;
    padding-bottom: $gutter;
  }
}

.content-container {
  @extend .l-container;
}

@include media($tablet) {

}

@include media($mobile) {

}