.news {
  @include display(flex);
  margin: 0 rem(-30);
}

.news--recent {
  margin-bottom: rem(-80);

  .news-post {
    flex: 1;

    + .news-post {
      border-left: 1px solid $white-off;
    }
  }

  .news-header {
    width: 100%;
    height: 180px !important;
    float: none;

    + .news-container {
      margin-left: 0;
    }
  }

  .news-heading {
    @extend .ms-1;
  }

  .news-header {
    display: none;
  }

  .news-dateline {
    @include font(15);
  }

  .news-desc p {
    @extend .ms-n1;
  }
}

.news--list {
  flex-direction: column;

  .news-post {
    margin-bottom: rem(50);
  }
}


.news-post {
  position: relative;
  padding: 0 rem(30) rem(30);
  @include clearfix;

  &:hover {
    .news-heading {
      color: $blue-dark;
    }

    .news-header {
      background-position: center top;
    }

    .news-header::after {
      opacity: 1;
    }
  }
}

.news-link {
  @extend %cover;
  z-index: 2;
}

.news-post--featured {
  .news-header {
    width: 100%;
    height: 250px;
    float: none;

    + .news-container {
      margin-left: 0;
    }
  }
}

.news-header {
  margin: 0 0 rem(20);
  position: relative;
  display: block;
  float: left;
  width: 150px;
  height: 150px;
  background: no-repeat center;
  background-size: cover;
  border-radius: 3px;
  @include transition(3s);

  &:before {
    content: "";
    @extend %cover;
    border-radius: 3px;
    @include linear-gradient(120deg, $blue 0%, $purple 90%);
    opacity: 0.2;
  }

  &:after {
    content: "";
    @extend %cover;
    border-radius: 3px;
    border: 2px solid $blue;
    opacity: 0;
    @include transition(0.35s $ease-in-out-quad);
  }

  + .news-container {
    margin-left: 170px;
  }
}

.news-heading {
  margin: 0 0 rem(10);
  @extend .ms-2;
  @include transition(0.35s $ease-in-out-quad);
}

.news-dateline {
  margin: 0;
  @include font(15);
  color: $base-type-light;
}

.news-desc p {
  margin: 0 0 rem(5);
}

.news-who {
  margin: 0;
  @include font(14);
}

.news-avatar {
  margin: 0 0 -40px;
  width: 70px;
  height: 70px;
  display: inline-block;
  background: $black-off;
  border-radius: 100%;
}


@include media($tablet) {

}

@include media($mobile) {
  .news {
    margin: 0;
    @include flex-direction(column);
  }

  .news-post {
    position: relative;
    padding: 0 rem(15);
    @include clearfix;

    + .news-post {
      margin-top: rem(20);
      border-left: none;
    }
  }

  .news--recent {
    margin-bottom: 0;

    .news-heading {
      font-size: 22px;
    }

    .news-header {
      display: none;
    }

    .news-dateline {
      @include font(14);
    }

    .news-desc p {
      @include font(16);
    }
  }
}
