.map {
  width: 100%;
  height: 80vh;
  display: block;
}

@include media($tablet) {

}

@include media($mobile) {

}
