.video {
  &.wrap-left,
  &.wrap-right {
    margin: 1em 0;
    width: 35%;
  }

  &.wrap-left {
    float: left;
    margin-right: 50px;
  }

  &.wrap-right {
    float: right;
    margin-left: 50px;
  }

  &.full {
    margin: 3em auto;
    text-align: center;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@include media($mobile) {
  .video {
    &.full,
    &.wrap-left,
    &.wrap-right {
      float: none;
      margin: 1em auto;
      width: 100%;
    }
  }
}
