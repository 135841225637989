//-----------------------------------------------------------
// COLOR
//-----------------------------------------------------------
// Colors specific to this project

//
// Primary Brand Colors
// ------------------------------------------
$white             : #ffffff;
$white-off         : #F8FAFB;
$white-offdark     : #f2f2f2;
$grey-light        : #dddddd;
$grey              : #bbbbbb;
$grey-dark         : #aaaaaa;
$grey-darker       : #999999;
$black-light       : #787878;
$black-off         : #4A4A4A;
$black             : #0A0B09;

$blue-dark         : #0079F3;

$blue-light        : #C5FFFF;
  $blue-light-t-20 : mix($blue-light, $white, 20%);

$blue              : #00C1F3;
  $blue-t-10       : mix($blue, $white, 10%);
  $blue-t-20       : mix($blue, $white, 20%);
  $blue-t-30       : mix($blue, $white, 30%);
  $blue-t-40       : mix($blue, $white, 40%);
  $blue-t-50       : mix($blue, $white, 50%);

$green             : #85B53A;
  $green-t-20      : mix($green, $white, 20%);
  $green-t-10      : mix($green, $white, 10%);
  $green-t-20      : mix($green, $white, 20%);
  $green-t-30      : mix($green, $white, 30%);
  $green-t-40      : mix($green, $white, 40%);
  $green-t-50      : mix($green, $white, 50%);

$red                : #F14950;
  $red-t-20         : mix($red, $white, 20%);

$yellow            : #F9E559;
  $yellow-t-20     : mix($yellow, $white, 20%);

$orange            : #EA9423;
  $orange-t-20     : mix($orange, $white, 20%);

$purple            : #DE9FC7;
  $purple-t-20     : mix($purple, $white, 20%);


$pink            : #F16482;
  $pink-t-20     : mix($pink, $white, 20%);

$base-heading       : #4A4A4A;
$base-type          : #787878;
$base-type-light    : #BBBBBB;
$base-border        : #F1F5F8;
$base-shadow        : rgba($black, 0.25);

$nav--topbar-item-color          : $base-heading;
$nav--topbar-item-color--hover   : $blue-dark;

$nav--subnav-item-color          : $black-off;
$nav--subnav-item-color--hover   : $blue;
$nav--subnav-stat-color          : $white;
$nav--subnav-stat-background     : $black-light;
$nav--subnav-item-border--active : $black-light;

$banner-background        : $blue-dark;

$bar-background        : $white;
$bar--dark-background  : $white-offdark;

$header-background        : $white-offdark;

$group-background        : $white-offdark;

$dropdown-text-color             : $black-off;
$dropdown-text-color--hover      : $black-off;
$dropdown-item-color             : $black-off;
$dropdown-item-color--hover      : $blue;
$dropdown-br-border              : $white-offdark;

$avatar-background  : $grey-light;

$footer-background : $white;
$footer-border     : $white-offdark;

$event-border         : $white-offdark;
$event-dateline-color : $base-type-light;

$feed-border         : $white-offdark;
$feed-dateline-color : $base-type-light;

$eventcal-heading-border  : $grey-light;

$featured-background      : $white;
$featured-border          : $white-offdark;
$featured-heading-border  : $grey-light;

$btn-bg             : transparent;
$btn-border         : $base-type;
$btn-color          : $base-type;
$btn-bg--hover      : $base-type;
$btn-border--hover  : $base-type;
$btn-color--hover   : $white;
$btn-bg--fill       : $base-type;
$btn-color--fill    : $white;

$btn-topbar-bg             : $blue;
$btn-topbar-border         : $blue;
$btn-topbar-color          : $white;
$btn-topbar-bg--hover      : darken($blue, 10%);
$btn-topbar-border--hover  : darken($blue, 10%);
$btn-topbar-color--hover   : $white;

$btn-primary-bg             : transparent;
$btn-primary-border         : $blue-dark;
$btn-primary-color          : $blue-dark;
$btn-primary-bg--hover      : $blue-dark;
$btn-primary-border--hover  : $blue-dark;
$btn-primary-color--hover   : $white;
$btn-primary-bg--fill       : $blue-dark;
$btn-primary-color--fill    : $white;
$btn-primary-border--fill--hover   : darken($blue-dark, 10%);
$btn-primary-bg--fill--hover       : darken($blue-dark, 10%);

$btn-secondary-bg             : transparent;
$btn-secondary-border         : $green;
$btn-secondary-color          : $green;
$btn-secondary-bg--hover      : $green;
$btn-secondary-border--hover  : $green;
$btn-secondary-color--hover   : $white;
$btn-secondary-bg--fill       : $green;
$btn-secondary-color--fill    : $white;
$btn-secondary-border--fill--hover   : darken($green, 10%);
$btn-secondary-bg--fill--hover       : darken($green, 10%);

$btn-danger-bg             : transparent;
$btn-danger-border         : $red;
$btn-danger-color          : $red;
$btn-danger-bg--hover      : $red;
$btn-danger-border--hover  : $red;
$btn-danger-color--hover   : $white;
$btn-danger-bg--fill       : $red;
$btn-danger-color--fill    : $white;
$btn-danger-border--fill--hover   : darken($red, 10%);
$btn-danger-bg--fill--hover       : darken($red, 10%);

$btn-grey-bg             : transparent;
$btn-grey-border         : $base-type;
$btn-grey-color          : $base-type;
$btn-grey-bg--hover      : $base-type;
$btn-grey-border--hover  : $base-type;
$btn-grey-color--hover   : $white;
$btn-grey-bg--fill       : $base-type;
$btn-grey-color--fill    : $white;
$btn-grey-border--fill--hover   : darken($base-type, 10%);
$btn-grey-bg--fill--hover       : darken($base-type, 10%);

$btn-white-bg             : transparent;
$btn-white-border         : $white;
$btn-white-color          : $white;
$btn-white-bg--hover      : $white;
$btn-white-border--hover  : $white;
$btn-white-color--hover   : $blue;
$btn-white-bg--fill       : $white;
$btn-white-color--fill    : $white;
$btn-white-border--fill--hover   : darken($white, 10%);
$btn-white-bg--fill--hover       : darken($white, 10%);
