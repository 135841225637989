@charset "UTF-8";
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *  user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio, canvas, progress, video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden], template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b, strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button, input, optgroup, select, textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button, select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled], html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *  (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev, .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.banner-container--home, .landingPage-container {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.banner-cover, .news-link, .news-header:before, .news-header:after, .landingPage-backgroundImage, .landingPage-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.u-textcenter {
  text-align: center; }

.u-textleft {
  text-align: left; }

.u-textright {
  text-align: right; }

body {
  color: #787878;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%; }

p, li {
  font-weight: 400;
  font-size: 1.125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    p, li {
      font-weight: 400;
      font-size: 1.0625rem;
      font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 1.7em; } }
  @media screen and (max-width: 39.99em)  {
    p, li {
      font-weight: 400;
      font-size: 1rem;
      font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 1.7em; } }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-size: 1.125rem;
  font-family: 'sirba-web', Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
  line-height: 1.1;
  color: #4A4A4A;
  margin: 1.5em 0 0.5em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    h1, h2, h3, h4, h5, h6 {
      font-weight: 400;
      font-size: 1.0625rem;
      font-family: 'sirba-web', Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
      line-height: 1.1; } }
  @media screen and (max-width: 39.99em)  {
    h1, h2, h3, h4, h5, h6 {
      font-weight: 400;
      font-size: 1rem;
      font-family: 'sirba-web', Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
      line-height: 1.1; } }

h1 {
  margin-top: 0; }

p {
  margin-bottom: 1.25em; }

a {
  color: #0079F3;
  text-decoration: none; }
  a:hover {
    color: #00C1F3; }

.ms-n0 {
  font-size: 1.125em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-n0 {
      font-size: 1.0625em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-n0 {
      font-size: 1em; } }

.ms-n1, .filter-heading, .news--recent .news-desc p {
  font-size: 0.9em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-n1, .filter-heading, .news--recent .news-desc p {
      font-size: 0.85em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-n1, .filter-heading, .news--recent .news-desc p {
      font-size: 0.8em; } }

.ms-n2 {
  font-size: 0.72em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-n2 {
      font-size: 0.68em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-n2 {
      font-size: 0.64em; } }

.ms-0, .quote-who {
  font-size: 1.125em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-0, .quote-who {
      font-size: 1.0625em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-0, .quote-who {
      font-size: 1em; } }

.ms-1, .rich-text > h5, .richtext > h5, .rich-text blockquote p, .richtext blockquote p, .heading4, .heading--4, .news--recent .news-heading, .quote-text, .sol-subheading {
  font-size: 1.40625em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-1, .rich-text > h5, .richtext > h5, .rich-text blockquote p, .richtext blockquote p, .heading4, .heading--4, .news--recent .news-heading, .quote-text, .sol-subheading {
      font-size: 1.32812em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-1, .rich-text > h5, .richtext > h5, .rich-text blockquote p, .richtext blockquote p, .heading4, .heading--4, .news--recent .news-heading, .quote-text, .sol-subheading {
      font-size: 1.25em; } }

.ms-2, .rich-text > h4, .richtext > h4, .heading3, .heading--3, .banner-subtitle, .banner-desc p, .findadvisor-heading, .news-heading, .landingPage-subtitle, .service-heading, .solution-heading, .sol-heading, .serv-heading {
  font-size: 1.75781em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-2, .rich-text > h4, .richtext > h4, .heading3, .heading--3, .banner-subtitle, .banner-desc p, .findadvisor-heading, .news-heading, .landingPage-subtitle, .service-heading, .solution-heading, .sol-heading, .serv-heading {
      font-size: 1.66016em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-2, .rich-text > h4, .richtext > h4, .heading3, .heading--3, .banner-subtitle, .banner-desc p, .findadvisor-heading, .news-heading, .landingPage-subtitle, .service-heading, .solution-heading, .sol-heading, .serv-heading {
      font-size: 1.5625em; } }

.ms-3, .rich-text > h3, .richtext > h3, .heading2, .heading--2, .band--intro .band-heading, .band-heading, .banner--inner .banner-title, .cta-heading, .page-heading {
  font-size: 2.19727em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-3, .rich-text > h3, .richtext > h3, .heading2, .heading--2, .band--intro .band-heading, .band-heading, .banner--inner .banner-title, .cta-heading, .page-heading {
      font-size: 2.0752em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-3, .rich-text > h3, .richtext > h3, .heading2, .heading--2, .band--intro .band-heading, .band-heading, .banner--inner .banner-title, .cta-heading, .page-heading {
      font-size: 1.95312em; } }

.ms-4, .rich-text > h2, .richtext > h2, .error-heading {
  font-size: 2.74658em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-4, .rich-text > h2, .richtext > h2, .error-heading {
      font-size: 2.59399em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-4, .rich-text > h2, .richtext > h2, .error-heading {
      font-size: 2.44141em; } }

.ms-5, .rich-text > h1, .richtext > h1, .banner--inner .banner-title, .banner-container--home .banner-title, .banner-title, .banner-container--large .banner-title, .banner-container--medium .banner-title, .landingPage-title {
  font-size: 3.43323em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-5, .rich-text > h1, .richtext > h1, .banner--inner .banner-title, .banner-container--home .banner-title, .banner-title, .banner-container--large .banner-title, .banner-container--medium .banner-title, .landingPage-title {
      font-size: 3.24249em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-5, .rich-text > h1, .richtext > h1, .banner--inner .banner-title, .banner-container--home .banner-title, .banner-title, .banner-container--large .banner-title, .banner-container--medium .banner-title, .landingPage-title {
      font-size: 3.05176em; } }

.ms-6 {
  font-size: 4.29153em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-6 {
      font-size: 4.05312em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-6 {
      font-size: 3.8147em; } }

.ms-7 {
  font-size: 5.36442em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-7 {
      font-size: 5.06639em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-7 {
      font-size: 4.76837em; } }

.ms-8 {
  font-size: 6.70552em; }
  @media screen and (min-width: 40em) and (max-width: 63.99em)  {
    .ms-8 {
      font-size: 6.33299em; } }
  @media screen and (max-width: 39.99em)  {
    .ms-8 {
      font-size: 5.96046em; } }

.ts-italic, .imageblock-caption, .pledge-text, .quote-text {
  font-style: italic; }

.ts-uppercase, .filter-heading, .pledge-type, .pledge-heading, .tweet-handle, .quote-who {
  font-weight: 700;
  text-transform: uppercase; }

.ts-light {
  font-weight: 300; }

.ts-alt, .rich-text blockquote p, .richtext blockquote p {
  font-family: 'aktiv-grotesk', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4;
  font-weight: 400; }

.rich-text :first-child, .richtext :first-child {
  margin-top: 0; }
.rich-text :last-child, .richtext :last-child {
  margin-bottom: 0; }
.rich-text > h5, .richtext > h5 {
  font-weight: 400; }
.rich-text blockquote, .richtext blockquote {
  text-align: center; }

@-webkit-keyframes landingPage-backgroundImage {
  from {
    -webkit-transform: translateX(0); }

  to {
    -webkit-transform: translateX(-25%); } }

@-moz-keyframes landingPage-backgroundImage {
  from {
    -moz-transform: translateX(0); }

  to {
    -moz-transform: translateX(-25%); } }

@keyframes landingPage-backgroundImage {
  from {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }

  to {
    -webkit-transform: translateX(-25%);
    -moz-transform: translateX(-25%);
    -ms-transform: translateX(-25%);
    -o-transform: translateX(-25%);
    transform: translateX(-25%); } }

html {
  box-sizing: border-box; }

body {
  background: #ffffff; }

img {
  max-width: 100%; }

.l-container, .bustout-container, .band-container, .banner-container, .content-container, .footer-container, .topbar-container, .landingPage-container {
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.875rem; }
  .l-container::after, .bustout-container::after, .band-container::after, .banner-container::after, .content-container::after, .footer-container::after, .topbar-container::after, .landingPage-container::after {
    clear: both;
    content: "";
    display: table; }

.l-cols {
  max-width: 68.75em;
  margin-left: auto;
  margin-right: auto; }
  .l-cols::after {
    clear: both;
    content: "";
    display: table; }

.l-centered {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%;
  margin-left: 25.58941%; }
  .l-centered:last-child {
    margin-right: 0; }

.l-main {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%; }
  .l-main:last-child {
    margin-right: 0; }

.l-side {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 31.76157%; }
  .l-side:last-child {
    margin-right: 0; }

.l-nofooter .footer {
  display: none; }

.l-site {
  overflow: hidden; }

.l-content {
  position: relative;
  z-index: 2;
  background: #ffffff; }

.l-tc, .heading, .richtext-content .rich-text > *, .formasp {
  max-width: 751px;
  margin-left: auto;
  margin-right: auto; }

@media screen and (min-width: 40em) and (max-width: 63.99em)  {
  .l-container, .bustout-container, .band-container, .banner-container, .content-container, .footer-container, .topbar-container, .landingPage-container {
    padding: 0 1.25rem; } }

@media screen and (max-width: 39.99em)  {
  .l-container, .bustout-container, .band-container, .banner-container, .content-container, .footer-container, .topbar-container, .landingPage-container {
    padding: 0 0.625rem; } }

.emphasis.wrap-right {
  width: 40%;
  float: right;
  margin: 30px 0% 30px 30px; }
.emphasis.wrap-left {
  width: 40%;
  float: left;
  margin: 30px 30px 30px 0%; }
.emphasis.full {
  margin: 30px 0% 30px 0%; }
  .emphasis.full .emphasis-text {
    font-weight: 300;
    font-size: 2.375rem;
    font-family: 'sirba-web', Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
    line-height: 1.4; }

.emphasis-text {
  margin: 0 0 0.625rem;
  padding: 0;
  font-weight: 300;
  font-size: 1.375rem;
  font-family: 'sirba-web', Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
  line-height: 1.4;
  color: #4A4A4A; }

.emphasis-subtext {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.2;
  font-style: italic;
  color: #BBBBBB; }

@media screen and (max-width: 39.99em)  {
  .emphasis.wrap-right, .emphasis.wrap-left, .emphasis.full {
    float: none;
    width: 100%;
    margin: 20px 0;
    padding: 0 15px; }
  .emphasis-text {
    font-weight: 300;
    font-size: 1.375rem;
    font-family: 'sirba-web', Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
    line-height: 1.4; }
  .emphasis-subtext {
    font-weight: 400;
    font-size: 1rem;
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.2; } }

.imageblock.wrap-right {
  width: 40%;
  float: right;
  margin: 30px 0% 30px 30px; }
.imageblock.wrap-left {
  width: 40%;
  float: left;
  margin: 30px 30px 30px 0%; }
.imageblock.full {
  margin: 30px 0% 30px 0%; }

.imageblock-caption {
  text-align: left;
  padding: 0 10px;
  margin: 0.625rem 0 0;
  font-weight: 400;
  font-size: 0.875rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

@media screen and (max-width: 39.99em)  {
  .imageblock.wrap-right, .imageblock.wrap-left, .imageblock.full {
    width: 100%;
    float: none;
    display: block;
    margin: 30px 0;
    padding: 0 15px; } }

.gallery {
  overflow: hidden; }

.slick-dots li button {
  width: 14px;
  height: 14px;
  border-radius: 20px;
  background-color: #f2f2f2; }
  .slick-dots li button:before {
    display: none; }
.slick-dots li.slick-active button {
  background-color: #0079F3; }

.introrichtext {
  max-width: 68.75em;
  margin-left: auto;
  margin-right: auto; }
  .introrichtext::after {
    clear: both;
    content: "";
    display: table; }

.introrichtext-content {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 74.41059%;
  margin-left: 12.79471%;
  text-align: center;
  padding: 1.875rem 0; }
  .introrichtext-content:last-child {
    margin-right: 0; }

.youtube {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .youtube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.bustout {
  margin: 80px 0;
  display: flex;
  align-items: stretch; }
  .bustout + .bustout {
    margin-top: -80px; }

.bustout--left .bustout-image {
  order: 1; }
.bustout--left .bustout-content {
  order: 2; }

.bustout--right .bustout-image {
  order: 2; }
.bustout--right .bustout-content {
  order: 1; }

.bustout--blue {
  background: #00C1F3; }
  .bustout--blue .bustout-content {
    color: #ffffff; }

.bustout--bluedark {
  background: #0079F3; }
  .bustout--bluedark .bustout-content {
    color: #ffffff; }

.bustout--black {
  background: #4A4A4A; }
  .bustout--black .bustout-content {
    color: #ffffff; }

.bustout-image {
  background-position: center;
  background-size: cover;
  width: 50%;
  min-height: 70vh; }

.bustout-content {
  width: 50%;
  padding: 50px;
  display: flex;
  align-items: center; }

.bustout-actions {
  margin: 20px 0; }

.bustout-container {
  padding: 0; }
  .bustout-container h2, .bustout-container h3, .bustout-container h4, .bustout-container h5 {
    color: #ffffff; }
  .bustout-container p {
    font-size: 20px; }

@media screen and (min-width: 40em) and (max-width: 63.99em)  {
  .bustout {
    margin: 40px 0; }
    .bustout + .bustout {
      margin-top: -40px; } }

@media screen and (max-width: 39.99em)  {
  .bustout {
    margin: 20px 0;
    flex-direction: column; }
    .bustout + .bustout {
      margin-top: -20px; }
  .bustout-image, .bustout-content {
    width: 100%; }
  .bustout-image {
    height: 300px; } }

.heading--left {
  text-align: left; }

.heading--center {
  text-align: center; }

.heading--right {
  text-align: right; }

@media screen and (max-width: 39.99em)  {
  .heading {
    padding: 0 15px; } }

.imagerow {
  margin: 80px 0; }
  .imagerow + .imagerow {
    margin-top: -80px; }

.imagerow--small {
  overflow: hidden; }
  .imagerow--small .imagerow-row {
    margin: 0 -3px; }
  .imagerow--small .imagerow-image {
    border: 3px solid transparent; }

.imagerow--large {
  overflow: hidden; }
  .imagerow--large .imagerow-row {
    margin: 0 -15px; }
  .imagerow--large .imagerow-image {
    border: 15px solid transparent; }

.imagerow-row {
  display: flex;
  justify-content: space-between; }

.imagerow-image {
  flex: auto; }
  .imagerow-image img {
    display: block;
    max-width: none;
    width: 100%; }

@media screen and (min-width: 40em) and (max-width: 63.99em)  {
  .imagerow {
    margin: 40px 0; }
    .imagerow + .imagerow {
      margin-top: -40px; } }

@media screen and (max-width: 39.99em)  {
  .imagerow {
    margin: 20px 0; }
    .imagerow + .imagerow {
      margin-top: -20px; }
  .imagerow-row {
    flex-direction: column; } }

@media screen and (max-width: 39.99em)  {
  .richtext-content .rich-text > * {
    padding: 0 15px; } }

.table-scroll {
  overflow-x: auto;
  margin: 30px 0; }

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  border: 1px solid #f2f2f2; }
  .table th + th, .table td + td, .table th + td, .table td + th {
    border-left: 1px solid #f2f2f2; }
  .table thead:first-child tr:first-child th, .table tbody:first-child tr:first-child th, .table tbody:first-child tr:first-child td {
    border-top: 0; }
  .table thead:first-child tr:first-child th:first-child, .table tbody:first-child tr:first-child th:first-child {
    border-radius: 4px 0 0; }
  .table thead:first-child tr:first-child th:last-child, .table tbody:first-child tr:first-child th:last-child {
    border-radius: 0 4px 0 0; }
  .table thead:last-child tr:last-child th:first-child, .table tbody:last-child tr:last-child th:first-child {
    border-radius: 0 0 0 4px; }
  .table thead:last-child tr:last-child th:last-child, .table tbody:last-child tr:last-child th:last-child {
    border-radius: 0 0 4px; }
  .table th, .table td {
    padding: 8px 16px;
    text-align: left;
    border-top: 1px solid #f2f2f2;
    color: #787878; }
  .table thead th {
    text-transform: uppercase;
    text-align: left;
    background-color: #0079F3;
    color: #ffffff;
    border-color: #419fff;
    vertical-align: bottom; }
  .table tbody tr:nth-child(even) {
    background-color: #F8FAFB; }
  .table tbody tr td {
    vertical-align: top; }

.table--first-col-header tbody tr td:first-child {
  text-transform: uppercase;
  background-color: #0079F3;
  color: #ffffff;
  border-color: #419fff; }

@media screen and (max-width: 39.99em)  {
  .table-scroll {
    margin: 100px 0; } }

.video.wrap-left, .video.wrap-right {
  margin: 1em 0;
  width: 35%; }
.video.wrap-left {
  float: left;
  margin-right: 50px; }
.video.wrap-right {
  float: right;
  margin-left: 50px; }
.video.full {
  margin: 3em auto;
  text-align: center; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media screen and (max-width: 39.99em)  {
  .video.full, .video.wrap-left, .video.wrap-right {
    float: none;
    margin: 1em auto;
    width: 100%; } }

.band, .introrichtext {
  position: relative;
  margin: 80px 0; }
  .band.is-nopad, .is-nopad.introrichtext {
    padding: 0; }

.band--solution {
  margin: 80px 0;
  padding: 0; }

.band--solutions {
  padding: 6.25rem 0;
  background-image: url(../images/bg-banner.jpg);
  background-color: #0079F3;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom; }
  .band--solutions .band-heading {
    color: #ffffff; }
    .band--solutions .band-heading:after {
      background: #ffffff; }

.band--offwhite, .introrichtext {
  background: #F8FAFB; }

.band--dark {
  background: #bbbbbb; }
  .band--dark .band-heading {
    color: #ffffff; }

.band--intro .band-content {
  font-size: 110%; }
.band--intro .band-heading {
  max-width: 80%; }

.band-stories, .band-updates {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%; }
  .band-stories:last-child, .band-updates:last-child {
    margin-right: 0; }

.band-heading {
  margin: 0 auto 1.875rem;
  padding-bottom: 1.5625rem;
  text-align: center;
  position: relative; }
  .band-heading:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 40px;
    left: 50%;
    bottom: 0;
    margin-left: -20px;
    background: #0079F3; }

.band-columns {
  margin: 0 -1.875rem;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.band-col {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 1.875rem; }

@media screen and (min-width: 40em) and (max-width: 63.99em)  {
  .band, .introrichtext {
    position: relative;
    margin: 50px 0; } }

@media screen and (max-width: 39.99em)  {
  .band, .introrichtext {
    position: relative;
    margin: 30px 0; }
  .band-columns {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column; } }

.banner {
  position: relative;
  text-align: center;
  background-image: url(../images/bg-banner.jpg);
  background-color: #0079F3;
  background-size: cover;
  background-position: center bottom;
  margin-bottom: 40px; }

.banner--home {
  margin-bottom: -80px; }

.banner--error .banner-content {
  padding-bottom: 0; }

.banner--home, .banner--large {
  height: 85vh; }

.banner--hasimage {
  background-position: center; }
  .banner--hasimage .banner-cover {
    opacity: 0.1; }

.banner--medium {
  height: 55vh; }

.banner-cover {
  opacity: 0;
  background-color: #00C1F3;
  background-image: -webkit-linear-gradient(-390deg, #00C1F3 0%, #DE9FC7 90%);
  background-image: linear-gradient(120deg,#00C1F3 0%, #DE9FC7 90%); }

.banner--default {
  text-align: left; }
  .banner--default .banner-content {
    padding: 5rem 0;
    max-width: 700px; }

.banner--inner {
  margin: 0 0 50px; }
  .banner--inner.is-image {
    margin: 0 0 110px; }
  .banner--inner .banner-container {
    padding-top: 120px;
    display: flex; }
  .banner--inner .banner-content {
    width: 59%;
    padding: 120px 60px 80px 0;
    text-align: left; }
  .banner--inner .banner-image {
    position: absolute;
    left: 56%;
    bottom: -90px;
    right: 0;
    top: 150px;
    overflow: hidden;
    border-radius: 3px 0 0 3px;
    box-shadow: 0 10px 20px rgba(61, 83, 102, 0.3); }
    .banner--inner .banner-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .banner--inner .banner-title {
    margin-bottom: 10px; }
  .banner--inner .banner-desc {
    margin-top: 5px;
    font-size: 150%; }

.banner-video {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto; }

.banner-container--home {
  padding-top: 120px;
  z-index: 2;
  text-align: left; }
  .banner-container--home .banner-content {
    padding: 5rem 0;
    max-width: 450px; }
  .banner-container--home .banner-title {
    margin: 0 0 0.625rem; }
  .banner-container--home .banner-cover {
    opacity: 0; }
  .banner-container--home .banner-action .btn:hover {
    color: #ffffff;
    background: #0079F3;
    border-color: #0079F3; }

.banner--large .banner-container, .banner--medium .banner-container {
  text-align: left;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center; }

.banner-content {
  position: relative;
  padding: 10rem 0 2.5rem;
  z-index: 300; }

.banner-title {
  margin: 0;
  padding: 0;
  font-weight: 200;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(10, 11, 9, 0.25); }

.banner-subtitle, .banner-desc p {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(10, 11, 9, 0.25); }

.banner-desc {
  margin-top: 1.25rem;
  color: #ffffff;
  font-weight: 400;
  font-size: 1.25rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em;
  text-shadow: 0 1px 1px rgba(10, 11, 9, 0.25); }

.banner-action {
  display: block;
  padding-top: 1.25rem; }
  .banner-action .btn {
    text-shadow: 0 1px 1px rgba(10, 11, 9, 0.1);
    box-shadow: 0 1px 1px rgba(10, 11, 9, 0.1); }
    .banner-action .btn:hover {
      text-shadow: none; }

@media screen and (min-width: 40em) and (max-width: 63.99em)  {
  .banner--home {
    margin-bottom: -50px; }
  .banner-container--home .banner-content, .banner-container--large .banner-content, .banner-container--medium .banner-content {
    max-width: 400px; } }

@media screen and (max-width: 39.99em)  {
  .banner--home {
    margin-bottom: -30px; }
  .banner-container--home, .banner-container--large, .banner-container--medium {
    text-align: center;
    padding-top: 80px; }
    .banner-container--home .banner-content, .banner-container--large .banner-content, .banner-container--medium .banner-content {
      padding: 2.5rem 0;
      width: 100%; }
    .banner-container--home .banner-title, .banner-container--large .banner-title, .banner-container--medium .banner-title {
      margin: 0 0 0.625rem; }
  .banner-content {
    padding: 2.5rem 0;
    padding: 7.5rem 0 2.5rem 0; }
  .banner-title {
    font-size: 38px; }
  .banner-video {
    display: none; }
  .banner--inner {
    margin: 0 0 50px; }
    .banner--inner.is-image {
      margin: 0 0 110px; }
    .banner--inner .banner-container {
      padding-top: 70px;
      display: flex;
      flex-direction: column; }
    .banner--inner .banner-content {
      width: 100%;
      margin: 20px 0;
      padding: 0;
      text-align: left; }
    .banner--inner .banner-image {
      margin: 20px 0 -30px;
      position: static;
      width: 100%;
      overflow: hidden;
      border-radius: 3px 0 0 3px;
      box-shadow: 0 10px 20px rgba(61, 83, 102, 0.3); }
      .banner--inner .banner-image img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .banner--inner .banner-desc {
      margin-top: 5px;
      font-size: 120%; } }

.branding {
  margin: 0;
  padding: 0;
  float: left; }

.branding-link {
  display: block;
  width: 200px;
  height: 36px;
  color: #4A4A4A;
  font-weight: 800;
  font-size: 1.375rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1; }
  .branding-link:hover {
    color: #0A0B09; }
  .branding-link svg path {
    -webkit-transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -moz-transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.branding-link--white .branding-brandfill {
  fill: rgba(255, 255, 255, 0); }
.branding-link--white .branding-brand, .branding-link--white .branding-text {
  fill: #ffffff;
  -webkit-filter: drop-shadow(0 1px 1px rgba(255, 255, 255, 0.1));
  filter: drop-shadow(0 1px 1px rgba(255, 255, 255, 0.1)); }

@media screen and (max-width: 39.99em)  {
  .branding-link, .branding-link svg {
    width: 150px;
    height: 27px; } }

.btn-container {
  padding: 1.25rem 0; }

.btn {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  border: 1px solid #787878;
  border-radius: 3px;
  cursor: pointer;
  color: #787878;
  outline: none;
  -webkit-transition: border 0.35s, background 0.35s, color 0.35s, opacity 0.35s;
  -moz-transition: border 0.35s, background 0.35s, color 0.35s, opacity 0.35s;
  transition: border 0.35s, background 0.35s, color 0.35s, opacity 0.35s;
  padding: 9px 25px;
  font-weight: 600;
  font-size: 1rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1;
  text-transform: capitalize; }
  .btn:hover {
    color: #ffffff;
    background-color: #787878;
    border-color: #787878; }
  .btn:active {
    opacity: 0.75; }

.btn-bar {
  margin: 0.5rem 0; }

.btn--blue, .btn--primary {
  background-color: transparent;
  border-color: #0079F3;
  color: #0079F3; }
  .btn--blue:hover, .btn--primary:hover {
    color: #ffffff;
    background-color: #0079F3;
    border-color: #0079F3; }
  .btn--blue.btn--fill, .btn--primary.btn--fill {
    color: #ffffff;
    background-color: #0079F3; }
    .btn--blue.btn--fill:hover, .btn--primary.btn--fill:hover {
      background-color: #0060c0;
      border-color: #0060c0; }

.btn--white {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff; }
  .btn--white:hover {
    color: #0079F3;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn--white.btn--fill {
    color: #0079F3;
    background-color: #ffffff; }
    .btn--white.btn--fill:hover {
      background-color: transparent;
      border-color: #ffffff;
      color: #ffffff; }

.btn--green, .btn-secondary {
  background-color: transparent;
  border-color: #85B53A;
  color: #85B53A; }
  .btn--green:hover, .btn-secondary:hover {
    color: #ffffff;
    background-color: #85B53A;
    border-color: #85B53A; }
  .btn--green.btn--fill, .btn-secondary.btn--fill {
    color: #ffffff;
    background-color: #85B53A; }
    .btn--green.btn--fill:hover, .btn-secondary.btn--fill:hover {
      background-color: #698e2e;
      border-color: #698e2e; }

.btn--landingPage {
  text-shadow: 0 1px 1px rgba(10, 11, 9, 0.1);
  box-shadow: 0 1px 1px rgba(10, 11, 9, 0.1);
  padding: 0.8125rem 2.34375rem 0.8125rem;
  vertical-align: top; }

.btn--square {
  border-radius: 0; }

.btn-danger {
  background-color: transparent;
  border-color: #F14950;
  color: #F14950; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #F14950;
    border-color: #F14950; }
  .btn-danger.btn--fill {
    color: #ffffff;
    background-color: #F14950; }
    .btn-danger.btn--fill:hover {
      background-color: #ed1a22;
      border-color: #ed1a22; }

.btn-grey {
  background-color: transparent;
  border-color: #787878;
  color: #787878; }
  .btn-grey:hover {
    color: #ffffff;
    background-color: #787878;
    border-color: #787878; }
  .btn-grey.btn--fill {
    color: #ffffff;
    background-color: #787878; }
    .btn-grey.btn--fill:hover {
      background-color: #5e5e5e;
      border-color: #5e5e5e; }

.btn-white {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff; }
  .btn-white:hover {
    color: #00C1F3;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white.btn--fill {
    color: #ffffff;
    background-color: #ffffff; }
    .btn-white.btn--fill:hover {
      background-color: #e6e6e6;
      border-color: #e6e6e6; }

.btn-ghost {
  border-color: transparent; }

.btn--small {
  padding: 7px 15px;
  border-width: 1px;
  font-weight: 600;
  font-size: 0.9375rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1; }

.btn--medium {
  padding: 9px 20px;
  border-width: 1px;
  font-weight: 600;
  font-size: 0.875rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1; }
  .btn--medium .btn-icon {
    width: 14px;
    height: 14px;
    margin: 0 5px 0 -8px; }

.btn--large {
  padding: 12px 30px;
  font-weight: 600;
  font-size: 1.125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1; }
  .btn--large .btn-icon {
    width: 24px;
    height: 24px;
    margin: -10px 8px -7px -10px; }

.btn--xlarge {
  padding: 13px 34px;
  font-weight: 600;
  font-size: 1.125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1; }

.btn--full {
  clear: both;
  display: block;
  width: 100%; }

.content {
  position: relative;
  padding: 3.236em 0; }

.content--page {
  margin-top: 80vh;
  z-index: 200;
  padding: 0; }
  .content--page .content-container {
    background: #ffffff;
    padding-top: 1.618em;
    padding-bottom: 1.618em; }

.cta {
  text-align: center; }

.cta--card {
  padding: 30px;
  border: 1px solid #dddddd; }

.cta-actions {
  padding-top: 0.625rem; }

.findadvisor {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.findadvisor-image {
  width: 50%;
  background: #F8FAFB;
  margin: 0 0 50px;
  background-position: center;
  background-size: cover; }

.findadvisor-content {
  position: relative;
  z-index: 2;
  background: #0079F3;
  padding: 3.125rem;
  width: 50%;
  width: -webkit-calc(50% + 50px);
  width: calc(50% + 50px);
  margin: 50px 0 0 -50px; }

.findadvisor-container {
  max-width: 470px; }

.findadvisor-heading {
  color: #ffffff; }

.findadvisor-text p {
  color: rgba(255, 255, 255, 0.7); }

.findadvisor-more {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600; }
  .findadvisor-more:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(-45deg);
    margin: 0 0 0 5px;
    top: -1px; }
  .findadvisor-more:hover {
    color: #00C1F3; }
    .findadvisor-more:hover:after {
      border-right: 2px solid #00C1F3;
      border-bottom: 2px solid #00C1F3; }

.findadvisor-action {
  text-align: right;
  margin-top: 1.25rem; }

@media screen and (max-width: 39.99em)  {
  .findadvisor {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column; }
  .findadvisor-image {
    width: 95%;
    margin: 0 0 -50px;
    height: 40vh; }
  .findadvisor-content {
    background: #0079F3;
    padding: 1.875rem;
    width: 95%;
    margin: 0 0 0 5%; } }

.filters {
  text-align: center; }

.filter-list {
  white-space: no-wrap; }

.filter {
  margin: 0 2px;
  padding: 10px 20px;
  background: #ffffff;
  display: inline-block;
  white-space: no-wrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .filter.is-active {
    background: #4A4A4A;
    color: #ffffff; }
  .filter:first-child {
    padding-left: 25px;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px; }
  .filter:last-child {
    padding-right: 25px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px; }

.footer {
  position: relative;
  z-index: 1;
  background: #F8FAFB;
  border-top: 1px solid #F8FAFB;
  margin-top: 80px;
  padding: 3.75rem 0 1.5625rem; }

.footer-heading {
  margin: 0;
  font-weight: 600;
  font-size: 0.9375rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1; }

.footer-copy {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.6;
  text-align: center;
  color: #A9BAC8; }
  .footer-copy a {
    color: #A9BAC8; }
    .footer-copy a:hover {
      color: #0079F3; }

.footer-heading {
  margin: 0 0 0.625rem;
  padding: 0;
  color: #4A4A4A;
  font-weight: 200;
  font-size: 1.0625rem;
  font-family: 'sirba-web', Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
  line-height: 1.1; }

.footer-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }

.footer-col {
  flex: 1;
  padding: 0 15px; }

.footer-left {
  text-align: left; }

.footer-right {
  text-align: right; }

.footer-tweet {
  padding-left: 1.25rem;
  border-left: 1px solid #999999; }

.footer-socials {
  margin-top: 1.875rem;
  margin-bottom: 0.3125rem;
  text-align: center;
  display: block; }

.footer-social {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  opacity: 1;
  -webkit-transition: 0.35s ease;
  -moz-transition: 0.35s ease;
  transition: 0.35s ease;
  opacity: 0.5; }
  .footer-social:hover {
    opacity: 1; }

@media screen and (max-width: 39.99em)  {
  .footer {
    padding: 1.875rem 0 1.5625rem;
    text-align: center; }
  .footer-heading {
    margin: 0.9375rem 0 0.625rem; }
  .footer-col {
    width: 100%;
    flex: 0 1 100%; } }

.form-titlebar {
  padding: 10px 0 30px; }

.form-heading {
  margin: 0 0 10px;
  padding: 0;
  font-weight: 400;
  font-size: 1.125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.form-half {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%; }
  .form-half:last-child {
    margin-right: 0; }

.field-set {
  max-width: 68.75em;
  margin-left: auto;
  margin-right: auto; }
  .field-set::after {
    clear: both;
    content: "";
    display: table; }

.field {
  margin-bottom: 30px; }

.field-checkbox {
  margin-bottom: 0.809em !important; }
  .field-checkbox .field-label {
    display: inline-block; }

.field-label {
  display: block;
  padding: 0 0;
  font-weight: 600;
  font-size: 1.0625rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.field-helptext {
  display: block;
  padding: 8px 0 0;
  font-weight: 400;
  font-size: 0.8125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.field-typeSelect select {
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  font-weight: 400;
  font-size: 0.8125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.field-multiselect select {
  width: 100%;
  outline: none;
  padding: 5px; }

.field-multiselect--large select {
  height: 200px; }

.field-text, .formasp p input[type="text"], .formasp p input[type="email"], .formasp p input[type="number"], .formasp p input[type="url"], .formasp p textarea {
  font-weight: 400;
  font-size: 1.125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em;
  -webkit-transition: box-shadow 0.25s;
  -moz-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  width: 100%;
  outline: none;
  background: #fff;
  padding: 5px;
  color: #4A4A4A;
  border: 1px solid #dddddd;
  box-shadow: none;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(10, 11, 9, 0.1); }
  .field-text:focus, .formasp p input[type="text"]:focus, .formasp p input[type="email"]:focus, .formasp p input[type="number"]:focus, .formasp p input[type="url"]:focus, .formasp p textarea:focus {
    border-color: #0079F3; }
  .field-text:-webkit-autofill, .formasp p input[type="text"]:-webkit-autofill, .formasp p input[type="email"]:-webkit-autofill, .formasp p input[type="number"]:-webkit-autofill, .formasp p input[type="url"]:-webkit-autofill, .formasp p textarea:-webkit-autofill {
    box-shadow: inset 0 0 0 100px #ffffff;
    color: #4A4A4A; }
    .field-text:-webkit-autofill:focus, .formasp p input[type="text"]:-webkit-autofill:focus, .formasp p input[type="email"]:-webkit-autofill:focus, .formasp p input[type="number"]:-webkit-autofill:focus, .formasp p input[type="url"]:-webkit-autofill:focus, .formasp p textarea:-webkit-autofill:focus {
      border-color: #0079F3; }

textarea {
  min-height: 150px; }

.field-text--long {
  height: 100px; }

.field--required .field-label:after {
  content: "*";
  color: #F14950; }

.field--long textarea {
  height: 150px; }

.field--error .field-label {
  color: #F14950; }
.field--error .field-text, .field--error .formasp p input[type="text"], .formasp p .field--error input[type="text"], .field--error .formasp p input[type="email"], .formasp p .field--error input[type="email"], .field--error .formasp p input[type="number"], .formasp p .field--error input[type="number"], .field--error .formasp p input[type="url"], .formasp p .field--error input[type="url"], .field--error .formasp p textarea, .formasp p .field--error textarea {
  border-color: #F14950; }
  .field--error .field-text:focus, .field--error .formasp p input[type="text"]:focus, .formasp p .field--error input[type="text"]:focus, .field--error .formasp p input[type="email"]:focus, .formasp p .field--error input[type="email"]:focus, .field--error .formasp p input[type="number"]:focus, .formasp p .field--error input[type="number"]:focus, .field--error .formasp p input[type="url"]:focus, .formasp p .field--error input[type="url"]:focus, .field--error .formasp p textarea:focus, .formasp p .field--error textarea:focus {
    border-color: #F14950;
    box-shadow: 0 2px #F14950; }
.field--error .alert--error {
  color: #F14950;
  padding: 0.75rem; }

.field--valid .field-text, .field--valid .formasp p input[type="text"], .formasp p .field--valid input[type="text"], .field--valid .formasp p input[type="email"], .formasp p .field--valid input[type="email"], .field--valid .formasp p input[type="number"], .formasp p .field--valid input[type="number"], .field--valid .formasp p input[type="url"], .formasp p .field--valid input[type="url"], .field--valid .formasp p textarea, .formasp p .field--valid textarea {
  border-color: #85B53A;
  box-shadow: 0 1px #85B53A; }
  .field--valid .field-text:focus, .field--valid .formasp p input[type="text"]:focus, .formasp p .field--valid input[type="text"]:focus, .field--valid .formasp p input[type="email"]:focus, .formasp p .field--valid input[type="email"]:focus, .field--valid .formasp p input[type="number"]:focus, .formasp p .field--valid input[type="number"]:focus, .field--valid .formasp p input[type="url"]:focus, .formasp p .field--valid input[type="url"]:focus, .field--valid .formasp p textarea:focus, .formasp p .field--valid textarea:focus {
    border-color: #0079F3;
    box-shadow: 0 2px #0079F3; }

.form--large .field-text, .form--large .formasp p input[type="text"], .formasp p .form--large input[type="text"], .form--large .formasp p input[type="email"], .formasp p .form--large input[type="email"], .form--large .formasp p input[type="number"], .formasp p .form--large input[type="number"], .form--large .formasp p input[type="url"], .formasp p .form--large input[type="url"], .form--large .formasp p textarea, .formasp p .form--large textarea {
  font-weight: 500;
  font-size: 1.5rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

@media screen and (max-width: 39.99em)  {
  .field {
    margin-bottom: 1.618em !important; }
    .field:last-child {
      margin-bottom: 1.618em !important; }
  .field-typeSelect {
    margin-bottom: 0; } }

.formasp {
  margin-top: 50px;
  margin-bottom: 50px; }
  .formasp ul {
    list-style: none;
    margin: -0.625rem 0 1.25rem 0.625rem;
    padding: 0; }
  .formasp .errorlist {
    margin-top: 1.25rem;
    position: relative;
    color: #F14950;
    border: 1px solid #F14950;
    border-radius: 3px;
    background: rgba(241, 73, 80, 0.1);
    margin: 0;
    padding: 0.5rem 0.9375rem; }
    .formasp .errorlist:first-child {
      margin-top: 0; }
    .formasp .errorlist:after {
      content: "";
      height: 0;
      width: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #F14950;
      position: absolute;
      bottom: -6px;
      left: 0.625rem; }
    .formasp .errorlist li {
      font-weight: 400;
      font-size: 0.875rem;
      font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 1.7em; }
    .formasp .errorlist + p label {
      color: #F14950; }
  .formasp p {
    margin: 0 0 0.9375rem; }
    .formasp p label {
      display: block;
      padding: 0.3125rem 0 0; }
    .formasp p select {
      -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      -moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      font-weight: 400;
      font-size: 1rem;
      font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 24px;
      display: block;
      height: 40px;
      padding: 6px 12px;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      width: 100%; }

.page {
  max-width: 68.75em;
  margin-left: auto;
  margin-right: auto; }
  .page::after {
    clear: both;
    content: "";
    display: table; }

.page--centered .page-main {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%;
  padding-right: 0; }
  .page--centered .page-main:last-child {
    margin-right: 0; }

.page-main {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%;
  padding-right: 15px; }
  .page-main:last-child {
    margin-right: 0; }

.page-side {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 31.76157%;
  padding-left: 15px; }
  .page-side:last-child {
    margin-right: 0; }

@media screen and (max-width: 39.99em)  {
  .page--centered .page-main {
    margin-left: 0%;
    float: left;
    display: block;
    margin-right: 7.42297%;
    width: 100%; }
    .page--centered .page-main:last-child {
      margin-right: 0; }
  .page-main {
    float: left;
    display: block;
    margin-right: 7.42297%;
    width: 100%; }
    .page-main:last-child {
      margin-right: 0; }
  .page-side {
    float: left;
    display: block;
    margin-right: 7.42297%;
    width: 100%; }
    .page-side:last-child {
      margin-right: 0; } }

.pagination {
  clear: both;
  display: block;
  padding-top: 1.875rem;
  text-align: center;
  list-style: none; }

.pagination-item {
  display: inline-block; }

.pagination-arrow .pagination-link {
  color: white;
  border-color: #4A4A4A;
  background: #4A4A4A; }

.pagination-link {
  margin: 0 -3px;
  padding: 10px 12px;
  font-weight: 600;
  font-size: 1rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em;
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #4A4A4A; }

.pledges-actions {
  text-align: center; }
  .pledges-actions .btn {
    margin: 0 0.625rem; }

.pledge {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 31.76157%;
  margin-bottom: 3.75rem; }
  .pledge:last-child {
    margin-right: 0; }
  .pledge:nth-child(3n) {
    margin-right: 0; }
  .pledge:nth-child(3n+1) {
    clear: left; }

.pledge--full {
  width: 100%;
  float: none; }

.pledge-container {
  background: #F8FAFB;
  text-align: center; }

.pledge-type {
  margin: 0;
  padding: 5px 0;
  background: #4A4A4A;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.8125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.pledge-content {
  text-align: center;
  padding: 1.875rem 1.875rem 0.625rem; }

.pledge-heading {
  margin: 0;
  font-weight: 600;
  font-size: 0.8125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.pledge-text {
  margin: 0 0 0.3125rem;
  font-weight: 400;
  font-size: 1.125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.pledge-who {
  margin: 0;
  font-weight: 400;
  font-size: 0.875rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.pledge-avatar {
  margin: 0 0 -40px;
  width: 70px;
  height: 70px;
  display: inline-block;
  background: #4A4A4A;
  border-radius: 100%; }

.map {
  width: 100%;
  height: 80vh;
  display: block; }

.nav {
  margin: 0;
  padding: 0;
  display: inline-block;
  line-height: 0; }

.nav-list {
  margin: 0;
  padding: 0;
  display: inline-block; }

.nav-item {
  margin: 0;
  padding: 0;
  float: left;
  display: block;
  vertical-align: middle; }

.nav-link {
  margin: 0;
  padding: 0;
  display: block;
  font-weight: 400;
  font-size: 0.9375rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1;
  -webkit-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.nav--topbar {
  float: right;
  padding: 0.625rem 0; }
  .nav--topbar .nav-item {
    padding: 0; }
    .nav--topbar .nav-item + .nav-item {
      margin-left: 35px; }
  .nav--topbar .nav-link {
    font-weight: 600;
    font-size: 1rem;
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1;
    color: #4A4A4A; }
    .nav--topbar .nav-link:hover {
      color: #0079F3; }
  .nav--topbar .nav-link--action {
    margin: -0.9375rem 0;
    padding: 0.9375rem 1.25rem 0.8125rem;
    background: #4A4A4A;
    color: #ffffff; }

.nav--user {
  float: right; }
  .nav--user .nav-item {
    padding: 0 0.625rem; }
  .nav--user .nav-link {
    font-weight: 400;
    font-size: 0.75rem;
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1;
    color: #4A4A4A; }
    .nav--user .nav-link:hover {
      color: #0079F3; }

.nav--side {
  display: block;
  margin: 0 0 1.875rem;
  padding: 0; }
  .nav--side .nav-heading {
    padding: 0 0.3125rem; }
  .nav--side .nav-list {
    border-top: 1px solid #dddddd;
    display: block; }
  .nav--side .nav-item {
    border-bottom: 1px solid #dddddd;
    float: none;
    display: block;
    padding: 0; }
  .nav--side .nav-link {
    padding: 0.9375rem 0.3125rem;
    display: block;
    font-weight: 400;
    font-size: 1.0625rem;
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1; }

.nav--footer .nav-item {
  margin: 0 0 0.625rem;
  padding: 0;
  display: block;
  float: none; }
.nav--footer .nav-link {
  font-weight: 400;
  font-size: 0.9375rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1; }

.nav-item--dropdown {
  position: relative; }
  .nav-item--dropdown .nav-link {
    padding-right: 10px;
    position: relative;
    -webkit-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -moz-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    .nav-item--dropdown .nav-link:before, .nav-item--dropdown .nav-link:after {
      content: "";
      position: absolute;
      top: 3px;
      right: 0;
      height: 5px;
      border-right: 1px solid #bbbbbb; }
    .nav-item--dropdown .nav-link:before {
      -webkit-transform: translateX(-3px) rotate(-45deg);
      -moz-transform: translateX(-3px) rotate(-45deg);
      -ms-transform: translateX(-3px) rotate(-45deg);
      -o-transform: translateX(-3px) rotate(-45deg);
      transform: translateX(-3px) rotate(-45deg); }
    .nav-item--dropdown .nav-link:after {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
  .nav-item--dropdown:hover .nav-menu {
    display: block;
    -webkit-transition: all 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
    -moz-transition: all 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
    transition: all 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    pointer-events: all; }

.nav-menu {
  position: absolute;
  top: 22px;
  left: 0;
  background: #ffffff;
  padding: 11px;
  opacity: 0;
  border-radius: 3px;
  -webkit-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -webkit-transform: translateY(-10px) scale(0.75);
  -moz-transform: translateY(-10px) scale(0.75);
  -ms-transform: translateY(-10px) scale(0.75);
  -o-transform: translateY(-10px) scale(0.75);
  transform: translateY(-10px) scale(0.75);
  -webkit-transform-origin: 20px top;
  -moz-transform-origin: 20px top;
  -ms-transform-origin: 20px top;
  -o-transform-origin: 20px top;
  transform-origin: 20px top;
  pointer-events: none;
  box-shadow: 0 1px 2px rgba(10, 11, 9, 0.25);
  z-index: 600; }
  .nav-menu:before {
    content: "";
    position: absolute;
    top: -8px;
    left: 15px;
    height: 0;
    width: 0;
    border-bottom: 8px solid rgba(10, 11, 9, 0.1);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent; }
  .nav-menu:after {
    content: "";
    position: absolute;
    top: -7px;
    left: 15px;
    height: 0;
    width: 0;
    border-bottom: 8px solid white;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent; }

.nav-menu--right {
  left: auto;
  right: 0;
  -webkit-transform-origin: 80% top;
  -moz-transform-origin: 80% top;
  -ms-transform-origin: 80% top;
  -o-transform-origin: 80% top;
  transform-origin: 80% top; }
  .nav-menu--right:before, .nav-menu--right:after {
    left: auto;
    right: 15px; }

.nav-menucatch {
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 10px; }

.nav-br {
  margin: 0.625rem 0;
  border-top: 1px solid #dddddd; }

.nav-menuitem {
  margin: 0;
  padding: 8px 10px;
  display: block;
  font-weight: 400;
  font-size: 0.9375rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1;
  text-decoration: none;
  color: #4A4A4A;
  white-space: nowrap;
  -webkit-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  text-align: left; }
  .nav-menuitem:hover {
    color: #00C1F3; }

.nav-menuitem--secondary {
  font-weight: 400;
  font-size: 0.8125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1;
  padding: 6px 10px; }

@media screen and (max-width: 39.99em)  {
  .nav--topbar {
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.35s ease;
    -moz-transition: 0.35s ease;
    transition: 0.35s ease;
    height: 0vh; }
    .nav--topbar .nav-list {
      height: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      box-orient: vertical;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      box-direction: normal;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      flex-direction: column;
      -ms-flex-direction: column; }
    .nav--topbar .nav-item {
      display: -webkit-box;
      display: -moz-box;
      display: box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      box-flex: 1;
      -webkit-flex: 1;
      -moz-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-box-align: center;
      -moz-box-align: center;
      box-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      -o-align-items: center;
      align-items: center;
      -ms-flex-align: center; }
    .nav--topbar .nav-link {
      width: 100%;
      display: block; }
    .nav--topbar .nav-item + .nav-item {
      margin: 0; }
    .nav--topbar .nav-link {
      padding: 0.9375rem 0; }
  .is-mobileOpen .nav--topbar {
    height: -webkit-calc(100vh - 65px);
    height: calc(100vh - 65px); } }

.news {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -1.875rem; }

.news--recent {
  margin-bottom: -5rem; }
  .news--recent .news-post {
    flex: 1; }
    .news--recent .news-post + .news-post {
      border-left: 1px solid #F8FAFB; }
  .news--recent .news-header {
    width: 100%;
    height: 180px !important;
    float: none; }
    .news--recent .news-header + .news-container {
      margin-left: 0; }
  .news--recent .news-header {
    display: none; }
  .news--recent .news-dateline {
    font-weight: 400;
    font-size: 0.9375rem;
    font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.7em; }

.news--list {
  flex-direction: column; }
  .news--list .news-post {
    margin-bottom: 3.125rem; }

.news-post {
  position: relative;
  padding: 0 1.875rem 1.875rem; }
  .news-post::after {
    clear: both;
    content: "";
    display: table; }
  .news-post:hover .news-heading {
    color: #0079F3; }
  .news-post:hover .news-header {
    background-position: center top; }
  .news-post:hover .news-header::after {
    opacity: 1; }

.news-link {
  z-index: 2; }

.news-post--featured .news-header {
  width: 100%;
  height: 250px;
  float: none; }
  .news-post--featured .news-header + .news-container {
    margin-left: 0; }

.news-header {
  margin: 0 0 1.25rem;
  position: relative;
  display: block;
  float: left;
  width: 150px;
  height: 150px;
  background: no-repeat center;
  background-size: cover;
  border-radius: 3px;
  -webkit-transition: 3s;
  -moz-transition: 3s;
  transition: 3s; }
  .news-header:before {
    content: "";
    border-radius: 3px;
    background-color: #00C1F3;
    background-image: -webkit-linear-gradient(-390deg, #00C1F3 0%, #DE9FC7 90%);
    background-image: linear-gradient(120deg,#00C1F3 0%, #DE9FC7 90%);
    opacity: 0.2; }
  .news-header:after {
    content: "";
    border-radius: 3px;
    border: 2px solid #00C1F3;
    opacity: 0;
    -webkit-transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -moz-transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .news-header + .news-container {
    margin-left: 170px; }

.news-heading {
  margin: 0 0 0.625rem;
  -webkit-transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955); }

.news-dateline {
  margin: 0;
  font-weight: 400;
  font-size: 0.9375rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em;
  color: #BBBBBB; }

.news-desc p {
  margin: 0 0 0.3125rem; }

.news-who {
  margin: 0;
  font-weight: 400;
  font-size: 0.875rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.news-avatar {
  margin: 0 0 -40px;
  width: 70px;
  height: 70px;
  display: inline-block;
  background: #4A4A4A;
  border-radius: 100%; }

@media screen and (max-width: 39.99em)  {
  .news {
    margin: 0;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column; }
  .news-post {
    position: relative;
    padding: 0 0.9375rem; }
    .news-post::after {
      clear: both;
      content: "";
      display: table; }
    .news-post + .news-post {
      margin-top: 1.25rem;
      border-left: none; }
  .news--recent {
    margin-bottom: 0; }
    .news--recent .news-heading {
      font-size: 22px; }
    .news--recent .news-header {
      display: none; }
    .news--recent .news-dateline {
      font-weight: 400;
      font-size: 0.875rem;
      font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 1.7em; }
    .news--recent .news-desc p {
      font-weight: 400;
      font-size: 1rem;
      font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 1.7em; } }

.topbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  text-align: center;
  z-index: 700;
  padding: 1.875rem 0 1.5625rem;
  -webkit-transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: 0.35s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .topbar:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    display: block;
    background-color: #00C1F3;
    background-image: -webkit-linear-gradient(-360deg, #00C1F3 0%, #0079F3 100%);
    background-image: linear-gradient(90deg,#00C1F3 0%, #0079F3 100%); }
  .topbar.is-scrolled {
    padding: 1.875rem 0 1.5625rem;
    box-shadow: 0 1px 1px rgba(74, 74, 74, 0.1); }

.topbar-bumper {
  height: 90px;
  display: block; }

.topbar--home:not(.is-mobileOpen) {
  background-color: rgba(0, 121, 243, 0.5);
  background-image: -webkit-linear-gradient(top, rgba(0, 121, 243, 0.5), rgba(0, 121, 243, 0));
  background-image: linear-gradient(to bottom,rgba(0, 121, 243, 0.5), rgba(0, 121, 243, 0));
  background-color: transparent; }
  .topbar--home:not(.is-mobileOpen) + .topbar-bumper {
    height: 0; }
  .topbar--home:not(.is-mobileOpen):not(.is-scrolled) {
    padding: 3.75rem 0 3.125rem; }
    .topbar--home:not(.is-mobileOpen):not(.is-scrolled) .nav--topbar .nav-link {
      color: #ffffff;
      text-shadow: 0 1px 1px rgba(10, 11, 9, 0.25); }
    .topbar--home:not(.is-mobileOpen):not(.is-scrolled) .branding-brandfill {
      fill: rgba(255, 255, 255, 0); }
    .topbar--home:not(.is-mobileOpen):not(.is-scrolled) .branding-brand, .topbar--home:not(.is-mobileOpen):not(.is-scrolled) .branding-text {
      fill: #ffffff; }
  .topbar--home:not(.is-mobileOpen).is-scrolled {
    background: #ffffff; }

.topbar-user {
  padding: 0 0 0.625rem; }

.topbar-ham {
  position: absolute;
  top: 18px;
  right: 15px;
  display: none;
  width: 32px;
  height: 32px;
  cursor: pointer; }
  .topbar-ham .rect {
    stroke-width: 0;
    stroke: #0079F3;
    fill: transparent;
    transform-origin: 50% 50%;
    transition: all 0.3s ease-in-out;
    transform: rotate(0deg); }
  .topbar-ham .cross-line {
    stroke: #0079F3;
    stroke-width: 0;
    transform-origin: 50% 50%;
    transition: all 0.3s ease-in-out; }
  .topbar-ham .nav-line {
    stroke: #0079F3;
    transform-origin: 50% 50%;
    stroke-width: 4px;
    transition: all 0.3s ease-in-out; }
  .topbar-ham:hover .nav-line {
    transform: scale(1); }
  .topbar-ham:hover #nav-line-1 {
    transform: translateY(5px) scale(1); }
  .topbar-ham:hover #nav-line-3 {
    transform: translateY(-5px) scale(1); }

@media screen and (min-width: 40em) and (max-width: 63.99em)  {
  .topbar--home:not(.is-mobileOpen) {
    padding: 2.5rem 0 1.875rem; } }

@media screen and (max-width: 39.99em)  {
  .topbar {
    padding: 1.25rem 0 1.125rem; }
    .topbar:before {
      height: 2px; }
    .topbar.is-scrolled {
      padding: 1.25rem 0 1.125rem; }
  .topbar--home:not(.is-mobileOpen) {
    padding: 1.25rem 0 1.125rem; }
    .topbar--home:not(.is-mobileOpen):not(.is-scrolled) {
      padding: 1.25rem 0 1.125rem; }
  .topbar-bumper {
    height: 65px; }
  .topbar-ham {
    display: block; }
  .is-mobileOpen .nav--topbar {
    opacity: 1;
    pointer-events: all; }
  .is-mobileOpen .cross-line {
    stroke-width: 4;
    transform: scale(1); }
  .is-mobileOpen .nav-line, .is-mobileOpen #nav-line-1, .is-mobileOpen #nav-line-3 {
    transform: scale(0.01); }
  .is-mobileOpen:hover .cross-line {
    transform: scale(1); }
  .is-mobileOpen:hover #nav-line-1, .is-mobileOpen:hover #nav-line-2, .is-mobileOpen:hover #nav-line-3 {
    stroke-width: 0; } }

.tweet-handle {
  margin: 0 0 0.3125rem;
  font-weight: 600;
  font-size: 0.9375rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.tweet-text {
  margin: 0 0 0.3125rem;
  font-weight: 400;
  font-size: 0.875rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.tweet-date {
  margin: 0 0 0.3125rem;
  font-weight: 400;
  font-size: 0.8125rem;
  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.7em; }

.quote {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 65.88078%;
  margin-left: 17.05961%;
  text-align: center; }
  .quote:last-child {
    margin-right: 0; }

.quote-text {
  margin-top: 0; }

.quote-who {
  margin-bottom: 0; }

.landingPage {
  position: relative;
  height: 100vh; }

.landingPage--hasimage .landingPage-cover {
  opacity: 0.25; }

.landingPage-backgroundImage {
  background: #0079F3;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 150%;
  -webkit-animation: landingPage-backgroundImage 45s linear infinite alternate-reverse;
  -moz-animation: landingPage-backgroundImage 45s linear infinite alternate-reverse;
  animation: landingPage-backgroundImage 45s linear infinite alternate-reverse;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.landingPage-cover {
  background-color: rgba(0, 193, 243, 0.75);
  background-image: -webkit-linear-gradient(-390deg, rgba(0, 193, 243, 0.75) 0%, rgba(222, 159, 199, 0.75) 90%);
  background-image: linear-gradient(120deg,rgba(0, 193, 243, 0.75) 0%, rgba(222, 159, 199, 0.75) 90%); }

.landingPage-content {
  position: relative;
  z-index: 100;
  max-width: 40rem; }

.landingPage-branding {
  margin-bottom: 1.875em; }

.landingPage-branding .branding-link, .landingPage-branding svg {
  height: 72px;
  width: 300px; }

.landingPage-title {
  padding: 0;
  font-weight: 200;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(10, 11, 9, 0.1); }

.landingPage-subtitle {
  padding: 0;
  font-weight: 300;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(10, 11, 9, 0.1); }

.landingPage-action-input {
  margin-bottom: 0;
  margin-right: 0.625rem;
  padding: 0.8125rem 0.75rem 0.6875rem;
  background: transparent;
  color: #ffffff;
  outline: none;
  border: 1px solid #dddddd;
  font-size: 1rem; }
  .landingPage-action-input::-webkit-input-placeholder {
    color: #aaaaaa; }
  .landingPage-action-input::-moz-placeholder {
    color: #aaaaaa; }
  .landingPage-action-input:-moz-placeholder {
    color: #aaaaaa; }
  .landingPage-action-input:-ms-input-placeholder {
    color: #aaaaaa; }
  .landingPage-action-input:focus {
    background: rgba(255, 255, 255, 0.1);
    border-color: #0079F3; }

.response {
  color: #dddddd;
  padding: 1rem;
  margin-bottom: .5rem;
  background: rgba(255, 255, 255, 0.1); }

.landingPage-formerly-anchor {
  color: #aaaaaa;
  padding-bottom: 0.125rem;
  border-bottom: 1px solid #aaaaaa; }
  .landingPage-formerly-anchor:hover {
    color: #dddddd;
    border-bottom: 1px solid #dddddd; }

@media screen and (min-width: 40em) and (max-width: 63.99em)  {
  .landingPage-action-input, .btn--landingPage {
    width: auto; }
  .landingPage-backgroundImage {
    -webkit-animation: landingPage-backgroundImage 29.25s linear infinite alternate-reverse;
    -moz-animation: landingPage-backgroundImage 29.25s linear infinite alternate-reverse;
    animation: landingPage-backgroundImage 29.25s linear infinite alternate-reverse; } }

@media screen and (max-width: 39.99em)  {
  .landingPage-action-input {
    margin-bottom: 0.625rem; }
  .landingPage-action-input, .btn--landingPage {
    width: 100%; }
  .landingPage-backgroundImage {
    -webkit-animation: landingPage-backgroundImage 18s linear infinite alternate-reverse;
    -moz-animation: landingPage-backgroundImage 18s linear infinite alternate-reverse;
    animation: landingPage-backgroundImage 18s linear infinite alternate-reverse; }
  .landingPage-branding {
    margin-bottom: 1.25em; }
  .landingPage-branding .branding-link, .landingPage-branding svg {
    height: 36px;
    width: 200px; } }

.columns {
  display: flex;
  flex-wrap: wrap; }

.column {
  padding: 10px;
  box-sizing: border-box; }

.column--left {
  flex: 1 1 50%; }

.column--right {
  flex: 1 1 50%; }

@media (max-width: 1024px) {
  .column {
    padding: 0; }
  .column--left, .column--right {
    flex: 1 0 100%;
    margin-top: 15px;
    margin-bottom: 15px; } }

.service {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.service-info {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  box-ordinal-group: 2;
  -webkit-order: 2;
  -moz-order: 2;
  order: 2;
  -ms-flex-order: 2; }

.service-heading {
  font-weight: 200;
  margin-bottom: -0.625rem; }

.service-icon {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  box-ordinal-group: 1;
  -webkit-order: 1;
  -moz-order: 1;
  order: 1;
  -ms-flex-order: 1;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  -webkit-flex: 0 0 60px;
  -moz-flex: 0 0 60px;
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  margin-right: 1.25rem; }

.service-action {
  text-align: right; }

.service-more {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600; }
  .service-more:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    border-right: 2px solid #0079F3;
    border-bottom: 2px solid #0079F3;
    transform: rotate(-45deg);
    margin: 0 0 0 5px;
    top: -1px; }
  .service-more:hover {
    color: #00C1F3; }
    .service-more:hover:after {
      border-right: 2px solid #00C1F3;
      border-bottom: 2px solid #00C1F3; }

@media screen and (max-width: 39.99em)  {
  .service {
    margin-top: 1.25rem; }
  .service-icon {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 40px;
    -moz-flex: 0 0 40px;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    margin-right: 0.9375rem; } }

.solution {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }
  .solution + .solution {
    margin-top: 3.75rem; }

.solution--list .solution-heading {
  color: #4A4A4A; }
.solution--list .solution-info p {
  color: #787878; }

.solution-info {
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  box-ordinal-group: 2;
  -webkit-order: 2;
  -moz-order: 2;
  order: 2;
  -ms-flex-order: 2;
  flex: 2; }
  .solution-info p {
    color: rgba(255, 255, 255, 0.7); }

.solution-links {
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  box-ordinal-group: 3;
  -webkit-order: 3;
  -moz-order: 3;
  order: 3;
  -ms-flex-order: 3;
  flex: 1;
  padding: 2.5rem 0 0 3.75rem; }
  .solution-links br {
    display: none; }
  .solution-links p {
    margin: 0; }
  .solution-links a {
    display: block;
    color: #ffffff;
    margin: 0 0 0.625rem; }
    .solution-links a:hover {
      color: #00C1F3; }

.solution-heading {
  font-weight: 200;
  margin-bottom: -0.625rem;
  color: #ffffff; }

.solution-icon {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  box-ordinal-group: 1;
  -webkit-order: 1;
  -moz-order: 1;
  order: 1;
  -ms-flex-order: 1;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  box-flex: 0;
  -webkit-flex: 0 1 60px;
  -moz-flex: 0 1 60px;
  -ms-flex: 0 1 60px;
  flex: 0 1 60px;
  margin-right: 1.25rem; }

.solution-image {
  background-size: cover;
  background-position: center; }

@media screen and (max-width: 39.99em)  {
  .solution {
    margin-top: 1.25rem;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .solution-info {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 80%;
    -moz-flex: 1 0 80%;
    -ms-flex: 1 0 80%;
    flex: 1 0 80%; }
  .solution-icon {
    -webkit-box-flex: 0;
    -moz-box-flex: 0;
    box-flex: 0;
    -webkit-flex: 0 0 40px;
    -moz-flex: 0 0 40px;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    margin-right: 0.9375rem; }
  .solution-links {
    padding-top: 0;
    padding-left: 55px;
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    box-ordinal-group: 3;
    -webkit-order: 3;
    -moz-order: 3;
    order: 3;
    -ms-flex-order: 3;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 80%;
    -moz-flex: 1 0 80%;
    -ms-flex: 1 0 80%;
    flex: 1 0 80%; }
    .solution-links a {
      margin: 0;
      font-weight: 400;
      font-size: 1rem;
      font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 1.7em; } }

.sol {
  display: flex; }

.sol-content {
  order: 2;
  padding-top: 30px; }

.sol-image {
  order: 1;
  flex: 0 0 300px;
  margin: 0 80px 0 0; }

.sol-img {
  margin: 0 -30px;
  width: 360px;
  max-width: none;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(61, 83, 102, 0.3); }

.sol-heading {
  font-weight: 200; }

.sol-subheading {
  font-weight: 200;
  margin-bottom: -10px; }

.sol-subtext {
  font-size: 105%; }

.sol-actions {
  margin: -10px 0 10px;
  text-align: right; }

.sol-more {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600; }
  .sol-more:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    border-right: 2px solid #0079F3;
    border-bottom: 2px solid #0079F3;
    transform: rotate(-45deg);
    margin: 0 0 0 5px;
    top: -1px; }
  .sol-more:hover {
    color: #00C1F3; }
    .sol-more:hover:after {
      border-right: 2px solid #00C1F3;
      border-bottom: 2px solid #00C1F3; }

@media screen and (max-width: 39.99em)  {
  .sol {
    display: flex;
    flex-direction: column; }
  .sol-image {
    text-align: center;
    order: 1;
    flex: 0 0 100%;
    margin: 0; }
  .sol-img {
    margin: 0 auto; } }

.band--serv:nth-child(even) .serv-content {
  order: 1; }
.band--serv:nth-child(even) .serv-image {
  order: 2;
  margin: 0 0 0 80px; }
.band--serv:nth-child(odd) .serv-content {
  order: 2; }
.band--serv:nth-child(odd) .serv-image {
  order: 1;
  margin: 0 80px 0 0; }

.serv {
  display: flex; }

.serv-content {
  padding-top: 30px;
  flex: 1;
  display: flex;
  align-items: center; }

.serv-info {
  width: 100%; }

.serv-image {
  flex: 0 0 360px; }

.serv-img {
  margin: 0 -30px;
  width: 420px;
  max-width: none;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(61, 83, 102, 0.3); }

.serv-heading {
  font-weight: 200; }

.serv-actions {
  text-align: right; }

.serv-more {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600; }
  .serv-more:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    border-right: 2px solid #0079F3;
    border-bottom: 2px solid #0079F3;
    transform: rotate(-45deg);
    margin: 0 0 0 5px;
    top: -1px; }
  .serv-more:hover {
    color: #00C1F3; }
    .serv-more:hover:after {
      border-right: 2px solid #00C1F3;
      border-bottom: 2px solid #00C1F3; }

.imagecontent {
  display: flex; }

.imagecontent--left .imagecontent-content {
  order: 2; }
.imagecontent--left .imagecontent-image {
  order: 1;
  margin: 0 80px 0 0; }

.imagecontent--right .imagecontent-content {
  order: 1; }
.imagecontent--right .imagecontent-image {
  order: 2;
  margin: 0 0 0 80px; }

.imagecontent-content {
  padding-top: 30px; }

.imagecontent-image {
  flex: 0 0 300px; }

.imagecontent-img {
  margin: 0 -30px;
  width: 360px;
  max-width: none;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(61, 83, 102, 0.3); }

@media screen and (max-width: 39.99em)  {
  .imagecontent {
    display: flex;
    flex-direction: column; }
  .imagecontent-image {
    text-align: center;
    order: 1;
    flex: 0 0 100%;
    margin: 0; }
  .imagecontent-img {
    margin: 0 auto; }
  .imagecontent--left .imagecontent-content, .imagecontent--right .imagecontent-content {
    order: 1; }
  .imagecontent--left .imagecontent-image, .imagecontent--right .imagecontent-image {
    order: 2;
    margin: 20px 0 0; } }

.error {
  padding: 40px 0 0;
  text-align: center; }

.accessibility-dims {
  width: 48px;
  height: 48px; }

.account-balance-dims {
  width: 48px;
  height: 48px; }

.account-balance-wallet-dims {
  width: 48px;
  height: 48px; }

.account-box-dims {
  width: 48px;
  height: 48px; }

.account-circle-dims {
  width: 48px;
  height: 48px; }

.add-shopping-cart-dims {
  width: 48px;
  height: 48px; }

.rotation-3D-dims {
  width: 48px;
  height: 48px; }

.background {
  width: 600px;
  height: 530px;
  float: left;
  background: url(../svgs/backgrounds-css.svg) no-repeat; }

.background--black {
  background-color: #333;
  margin-bottom: 250px; }

.background--1 {
  background-position: 0 0; }

.background--2 {
  background-position: 100% 0; }

.background--3 {
  background-position: 0 100%; }

