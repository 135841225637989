.table-scroll {
  overflow-x: auto;
  margin: 30px 0;
}

.table {
  // @extend .borderRadius-md;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  border: 1px solid $white-offdark;


  th + th,
  td + td,
  th + td,
  td + th {
    border-left: 1px solid $white-offdark;
  }

  // sass-lint:disable force-element-nesting
  thead:first-child tr:first-child th,
  tbody:first-child tr:first-child th,
  tbody:first-child tr:first-child td {
    border-top: 0;
  }
  // sass-lint:enable force-element-nesting

  // sass-lint:disable nesting-depth
  thead,
  tbody {
    &:first-child {
      tr:first-child {
        th {
          &:first-child {
            border-radius: 4px 0 0;
          }

          &:last-child {
            border-radius: 0 4px 0 0;
          }
        }
      }
    }

    &:last-child {
      tr:last-child {
        th {
          &:first-child {
            border-radius: 0 0 0 4px;
          }

          &:last-child {
            border-radius: 0 0 4px;
          }
        }
      }
    }
  }
  // sass-lint:enable nesting-depth

  th,
  td {
    // @extend .fontSize-xxs;
    padding: 8px 16px;
    text-align: left;
    border-top: 1px solid $white-offdark;
    color: $base-type;
  }

  thead {
    th {
      // @extend .fontWeight-bold;
      // @extend .letterSpacing-md;
      text-transform: uppercase;
      text-align: left;
      background-color: $blue-dark;
      color: $white;
      border-color: lighten($blue-dark, 15%);
      vertical-align: bottom;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: $white-off;
      }

      td {
        vertical-align: top;
      }
    }
  }
}

.table--first-col-header {
  tbody {
    tr {
      td:first-child { // sass-lint:disable-line nesting-depth
        // @extend .fontWeight-bold;
        // @extend .letterSpacing-md;
        text-transform: uppercase;
        background-color: $blue-dark;
        color: $white;
        border-color: lighten($blue-dark, 15%);
      }
    }
  }
}

@include media($mobile) {
  .table-scroll {
    margin: 100px 0;
  }
}
