.block-intro {

}

.introrichtext {
  @include outer-container;
  @extend .band;
  @extend .band--offwhite;
}

.introrichtext-content {
  @include span-columns(9);
  @include shift(1.5);
  text-align: center;
  padding: rem(30) 0;
}

@include media($tablet) {

}

@include media($mobile) {

}
