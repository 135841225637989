.bustout {
  margin: 80px 0;
  display: flex;
  align-items: stretch;

  + .bustout {
    margin-top: -80px;
  }
}

.bustout--left {
  .bustout-image {
    order: 1;
  }

  .bustout-content {
    order: 2;
  }
}

.bustout--right {
  .bustout-image {
    order: 2;
  }

  .bustout-content {
    order: 1;
  }
}

.bustout--blue {
  background: $blue;

  .bustout-content {
    color: $white;
  }
}

.bustout--bluedark {
  background: $blue-dark;

  .bustout-content {
    color: $white;
  }
}

.bustout--black {
  background: $black-off;

  .bustout-content {
    color: $white;
  }
}

.bustout-image {
  background-position: center;
  background-size: cover;
  width: 50%;
  min-height: 70vh;
  // max-height: 800px;
}

.bustout-content {
  width: 50%;
  padding: 50px;
  display: flex;
  align-items: center;
}

.bustout-actions {
  margin: 20px 0;
}

.bustout-container {
  @extend .l-container;
  padding: 0;

  h2, h3, h4, h5 {
    color: $white;
  }

  p {
    font-size: 20px;
  }
}

@include media($tablet) {
  .bustout {
    margin: 40px 0;

    + .bustout {
      margin-top: -40px;
    }
  }
}

@include media($mobile) {
  .bustout {
    margin: 20px 0;
    flex-direction: column;

    + .bustout {
      margin-top: -20px;
    }
  }

  .bustout-image,
  .bustout-content {
    width: 100%;
  }

  .bustout-image {
    height: 300px;
  }
}
