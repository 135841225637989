.branding {
  margin: 0;
  padding: 0;
  float: left;
}

.branding-link {
  display: block;
  width: 200px;
  height: 36px;
  color: $base-heading;
  @include font(22, 800, 1);

  &:hover {
    color: $black;
  }

  svg path {
    @include transition(0.35s $ease-in-out-quad);
  }
}

.branding-link--white {
  .branding-brandfill {
    fill: rgba($white, 0);
  }

  .branding-brand,
  .branding-text {
    fill: $white;
    -webkit-filter: drop-shadow(0 1px 1px rgba($white, 0.1));
    filter: drop-shadow(0 1px 1px rgba($white, 0.1));
  }
}

.branding-text {

}

@include media($tablet) {

}

@include media($mobile) {
  .branding-link,
  .branding-link svg {
    width: 150px;
    height: 27px;
  }
}
