.pledges {

}

.pledges-actions {
  text-align: center;

  .btn {
    margin: 0 rem(10);
  }
}

.pledge {
  @include span-columns(4);
  @include omega(3n);
  margin-bottom: rem(60);
}

.pledge--full {
  width: 100%;
  float: none;
}

.pledge-container {
  background: $white-off;
  text-align: center;
}

.pledge-type {
  margin: 0;
  padding: 5px 0;
  background: $black-off;
  color: $white;
  @include font(13, 600);
  @extend .ts-uppercase;
}

.pledge-content {
  text-align: center;
  padding: rem(30) rem(30) rem(10);
}

.pledge-heading {
  margin: 0;
  @include font(13, 600);
  @extend .ts-uppercase;
}

.pledge-text {
  margin: 0 0 rem(5);
  @include font(18);
  @extend .ts-italic;
}

.pledge-who {
  margin: 0;
  @include font(14);
}

.pledge-avatar {
  margin: 0 0 -40px;
  width: 70px;
  height: 70px;
  display: inline-block;
  background: $black-off;
  border-radius: 100%;
}


@include media($tablet) {

}

@include media($mobile) {

}
