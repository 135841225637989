.l-container {
  @include outer-container(1060px);
  padding: 0 rem(30);
}

.l-cols {
  @include outer-container();
}

.l-centered {
  @include span-columns(6);
  @include shift(3);
}

.l-main {
  @include span-columns(8);
}

.l-side {
  @include span-columns(4);
}

.l-nofooter {
  .footer {
    display: none;
  }
}

.l-site {
  overflow: hidden;
}

.l-content {
  position: relative;
  z-index: 2;
  background: $white;
}

.l-tc {
  max-width: 751px;
  margin-left: auto;
  margin-right: auto;
}

@include media($tablet) {
  .l-container {
    padding: 0 rem(20);
  }
}

@include media($mobile) {
  .l-container {
    padding: 0 rem(10);
  }
}
