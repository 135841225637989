//
// MODULES
//

@import "__layout";
@import "streamfield/__streamfield";
@import "streamfield2/__streamfields";

@import "_band";
@import "_banner";
@import "_branding";
@import "_btn";
@import "_content";
@import "_cta";
@import "_findadvisor";
@import "_filters";
@import "_footer";
@import "_forms";
@import "_page";
@import "_pagination";
@import "_pledges";
@import "_post";
@import "_map";
@import "_nav";
@import "_news";
@import "_topbar";
@import "_tweet";
@import "_quote";
@import "_landingPage";
@import "_columns";


.service {
  @include display(flex);
}

.service-info {
  @include order(2);
}

.service-heading {
  @extend .ms-2;
  font-weight: 200;
  margin-bottom: rem(-10);
}

.service-icon {
  @include order(1);
  @include flex(0 0 60px);
  margin-right: rem(20);
}

.service-action {
  text-align: right;
}

.service-more {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;

  &:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    border-right: 2px solid $blue-dark;
    border-bottom: 2px solid $blue-dark;
    transform: rotate(-45deg);
    margin: 0 0 0 5px;
    top: -1px;
  }

  &:hover {
    color: $blue;

    &:after {
      border-right: 2px solid $blue;
      border-bottom: 2px solid $blue;
    }
  }
}

@include media($mobile) {
  .service {
    margin-top: rem(20);
  }

  .service-icon {
    @include flex(0 0 40px);
    margin-right: rem(15);
  }
}


.solution {
  @include display(flex);

  + .solution {
    margin-top: rem(60);
  }
}

.solution--list {
  .solution-heading {
    color: $base-heading;
  }
  .solution-info {
    p {
      color: $base-type;
    }
  }
}

.solution-info {
  @include order(2);
  flex: 2;

  p {
    color: rgba($white, 0.7);
  }
}

.solution-links {
  @include order(3);
  flex: 1;
  padding: rem(40) 0 0 rem(60);

  br {
    display: none;
  }

  p {
    margin: 0;
  }

  a {
    display: block;
    color: $white;
    margin: 0 0 rem(10);

    &:hover {
      color: $blue;
    }
  }
}

.solution-heading {
  @extend .ms-2;
  font-weight: 200;
  margin-bottom: rem(-10);
  color: $white;
}

.solution-icon {
  @include order(1);
  @include flex(0 1 60px);
  margin-right: rem(20);
}

.solution-image {
  background-size: cover;
  background-position: center;
}

@include media($mobile) {
  .solution {
    margin-top: rem(20);
    @include flex-wrap(wrap);
  }

  .solution-info {
    @include flex(1 0 80%);
  }

  .solution-icon {
    @include flex(0 0 40px);
    margin-right: rem(15);
  }

  .solution-links {
    padding-top: 0;
    padding-left: 55px;
    @include order(3);
    @include flex(1 0 80%);

    a {
      margin: 0;
      @include font(16);
    }
  }
}



.sol {
  display: flex;
}

.sol-content {
  order: 2;
  padding-top: 30px;
}

.sol-image {
  order: 1;
  flex: 0 0 300px;
  margin: 0 80px 0 0;
}

.sol-img {
  margin: 0 -30px;
  width: 360px;
  max-width: none;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(#3D5366, 0.3);
}

.sol-heading {
  @extend .ms-2;
  font-weight: 200;
}

.sol-sections {
  // padding-top: 5px;
}

.sol-subheading {
  @extend .ms-1;
  font-weight: 200;
  margin-bottom: -10px;
}

.sol-subtext {
  font-size: 105%;
}

.sol-actions {
  margin: -10px 0 10px;
  text-align: right;
}

.sol-more {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;

  &:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    border-right: 2px solid $blue-dark;
    border-bottom: 2px solid $blue-dark;
    transform: rotate(-45deg);
    margin: 0 0 0 5px;
    top: -1px;
  }

  &:hover {
    color: $blue;

    &:after {
      border-right: 2px solid $blue;
      border-bottom: 2px solid $blue;
    }
  }
}

@include media($mobile) {
  .sol {
    display: flex;
    flex-direction: column;
  }

  .sol-image {
    text-align: center;
    order: 1;
    flex: 0 0 100%;
    margin: 0;
  }

  .sol-img {
    margin: 0 auto;
  }
}

.band--serv {
  &:nth-child(even) {
    .serv-content {
      order: 1;
    }
    .serv-image {
      order: 2;
      margin: 0 0 0 80px;
    }
  }

  &:nth-child(odd) {
    .serv-content {
      order: 2;
    }
    .serv-image {
      order: 1;
      margin: 0 80px 0 0;
    }
  }
}

.serv {
  display: flex;
}

.serv-content {
  padding-top: 30px;
  flex: 1;
  display: flex;
  align-items: center;
}

.serv-info {
  width: 100%;
}

.serv-image {
  flex: 0 0 360px;
}

.serv-img {
  margin: 0 -30px;
  width: 420px;
  max-width: none;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(#3D5366, 0.3);
}

.serv-heading {
  @extend .ms-2;
  font-weight: 200;
}

.serv-actions {
  text-align: right;
}

.serv-more {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;

  &:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    border-right: 2px solid $blue-dark;
    border-bottom: 2px solid $blue-dark;
    transform: rotate(-45deg);
    margin: 0 0 0 5px;
    top: -1px;
  }

  &:hover {
    color: $blue;

    &:after {
      border-right: 2px solid $blue;
      border-bottom: 2px solid $blue;
    }
  }
}




.imagecontent {
  display: flex;
}

.imagecontent--left {
  .imagecontent-content {
    order: 2;
  }

  .imagecontent-image {
    order: 1;
    margin: 0 80px 0 0;
  }
}

.imagecontent--right {
  .imagecontent-content {
    order: 1;
  }

  .imagecontent-image {
    order: 2;
    margin: 0 0 0 80px;
  }
}

.imagecontent-content {
  padding-top: 30px;
}

.imagecontent-image {
  flex: 0 0 300px;
}

.imagecontent-img {
  margin: 0 -30px;
  width: 360px;
  max-width: none;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(#3D5366, 0.3);
}

@include media($mobile) {
  .imagecontent {
    display: flex;
    flex-direction: column;
  }

  .imagecontent-image {
    text-align: center;
    order: 1;
    flex: 0 0 100%;
    margin: 0;
  }

  .imagecontent-img {
    margin: 0 auto;
  }

  .imagecontent--left,
  .imagecontent--right {
    .imagecontent-content {
      order: 1;
    }

    .imagecontent-image {
      order: 2;
      margin: 20px 0 0;
    }
  }
}


.error {
  padding: 40px 0 0;
  text-align: center;
}

.error-heading {
  @extend .ms-4;
}
