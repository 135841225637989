.tweet-handle {
  margin: 0 0 rem(5);
  @include font(15, 600);
  @extend .ts-uppercase;
}

.tweet-text {
  margin: 0 0 rem(5);
  @include font(14);
}


.tweet-date {
  margin: 0 0 rem(5);
  @include font(13);
}

@include media($tablet) {

}

@include media($mobile) {

}
