.band {
  position: relative;
  margin: 80px 0;

  &.is-nopad {
    padding: 0;
  }
}

.band--solution {
  margin: 80px 0;
  padding: 0;
}

.band--solutions {
  padding: rem(100) 0;
  background-image: url(../images/bg-banner.jpg);
  background-color: $banner-background;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;

  .band-heading {
    color: $white;

    &:after {
      background: $white;
    }
  }
}

.band--offwhite {
  background: $white-off;
}

.band--dark {
  background: $grey;

  .band-heading {
    color: $white;
  }
}

.band--intro {
  .band-content {
    font-size: 110%;
  }

  .band-heading {
    @extend .ms-3;
    max-width: 80%;
  }
}

.band-container {
  @extend .l-container;
}

.band-stories,
.band-updates {
  @include span-columns(6);
}

.band-heading {
  margin: 0 auto rem(30);
  padding-bottom: rem(25);
  text-align: center;
  @extend .ms-3;
  position: relative;


  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 40px;
    left: 50%;
    bottom: 0;
    margin-left: -20px;
    background: $blue-dark;
  }
}

.band-columns {
  margin: 0 rem(-30);
  @include display(flex);
}

.band-col {
  @include flex(1);
  padding: 0 rem(30);
}

@include media($tablet) {
  .band {
    position: relative;
    margin: 50px 0;
  }
}

@include media($mobile) {
  .band {
    position: relative;
    margin: 30px 0;
  }

  .band-columns {
    @include flex-direction(column);
  }
}
