.quote {
  @include span-columns(8);
  @include shift(2);
  text-align: center;
}

.quote-text {
  margin-top: 0;
  @extend .ms-1;
  @extend .ts-italic;
}

.quote-who {
  margin-bottom: 0;
  @extend .ms-0;
  @extend .ts-uppercase;
}

@include media($tablet) {

}

@include media($mobile) {

}
