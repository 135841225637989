.footer {
  position: relative;
  z-index: 1;
  background: $white-off;
  border-top: 1px solid $white-off;
  margin-top: 80px;
  padding: rem(60) 0 rem(25);
}

.footer-container {
  @extend .l-container;
}

.footer-heading {
  margin: 0;
  @include font(15, 600, 1);
}

.footer-copy {
  margin: 0;
  padding: 0;
  @include font(14, 400, 1.6);
  text-align: center;
  color: #A9BAC8;

  a {
    color: #A9BAC8;

    &:hover {
      color: $blue-dark;
    }
  }
}

.footer-heading {
  margin: 0 0 rem(10);
  padding: 0;
  color: $base-heading;
  @include font(17, 200, 1.1, $base-font-heading);
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.footer-col {
  flex: 1;
  padding: 0 15px;
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}

.footer-tweet {
  padding-left: rem(20);
  border-left: 1px solid $grey-darker;
}

.footer-socials {
  margin-top: rem(30);
  margin-bottom: rem(5);
  text-align: center;
  display: block;
}

.footer-social {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  opacity: 1;
  @include transition(0.35s ease);
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

@include media($tablet) {

}

@include media($mobile) {
  .footer {
    padding: rem(30) 0 rem(25);
    text-align: center;
  }

  .footer-heading {
    margin: rem(15) 0 rem(10);
  }

  .footer-col {
    width: 100%;
    flex: 0 1 100%;
  }
}
