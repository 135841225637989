.pagination {
  clear: both;
  display: block;
  padding-top: rem(30);
  text-align: center;
  list-style: none;
}

.pagination-item {
  display: inline-block;
}

.pagination-arrow {
  .pagination-link {
    color: white;
    border-color: $black-off;
    background: $black-off;
  }
}

.pagination-link {
  margin: 0 -3px;
  padding: 10px 12px;
  @include font(16, 600);
  border: 1px solid $grey-light;
  background: $white;
  color: $black-off;
}

@include media($tablet) {

}

@include media($mobile) {

}
