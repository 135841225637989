.topbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: $white;
  text-align: center;
  z-index: $z-topbar;
  padding: rem(30) 0 rem(25);
  @include transition(0.35s $ease-in-out-quad);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    display: block;
    @include linear-gradient(90deg, $blue 0%, $blue-dark 100%);
  }

  &.is-scrolled {
    padding: rem(30) 0 rem(25);
    box-shadow: 0 1px 1px rgba($black-off, 0.1);
  }
}

.topbar-bumper {
  height: 90px;
  display: block;
}

.topbar--home:not(.is-mobileOpen) {
  @include linear-gradient(to bottom, rgba($blue-dark, 0.5), rgba($blue-dark, 0));
  background-color: transparent;

  + .topbar-bumper {
    height: 0;
  }

  &:not(.is-scrolled) {
    padding: rem(60) 0 rem(50);

    .nav--topbar .nav-link {
      color: $white;
      text-shadow: 0 1px 1px rgba($black, 0.25);
    }

    .branding-brandfill {
      fill: rgba($white, 0);
    }

    .branding-brand,
    .branding-text {
      fill: $white;
    }
  }

  &.is-scrolled {
    background: $white;
  }
}

.topbar-user {
  padding: 0 0 rem(10);
}

.topbar-container {
  @extend .l-container;
}

$size: 32px;
$primaryColor: $blue-dark;

.topbar-ham {
  position: absolute;
  top: 18px;
  right: 15px;
  display: none;
  width: $size;
  height: $size;
  cursor: pointer;

  .rect {
    stroke-width: 0;
    stroke: $primaryColor;
    fill: transparent;
    transform-origin: 50% 50%;
    transition: all 0.3s ease-in-out;
    transform: rotate(0deg);
  }

  .cross-line {
    stroke: $primaryColor;
    stroke-width: 0;
    transform-origin: 50% 50%;
    transition: all 0.3s ease-in-out;
  }
  .nav-line {
    stroke: $primaryColor;
    transform-origin: 50% 50%;
    stroke-width: 4px;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .rect {
      // stroke-width: 1;
      // transform: rotate(45deg);
    }
    .nav-line {
      transform: scale(1);
    }
    #nav-line-1 {
      transform: translateY(5px) scale(1);
    }

    #nav-line-3 {
      transform: translateY(-5px) scale(1);
    }
  }
}

@include media($tablet) {
  .topbar--home:not(.is-mobileOpen) {
    padding: rem(40) 0 rem(30);
  }
}

@include media($mobile) {
  .topbar {
    padding: rem(20) 0 rem(18);

    &:before {
      height: 2px;
    }

    &.is-scrolled {
      padding: rem(20) 0 rem(18);
    }
  }

  .topbar--home:not(.is-mobileOpen) {
    padding: rem(20) 0 rem(18);

    &:not(.is-scrolled) {
      padding: rem(20) 0 rem(18);
    }
  }

  .topbar-bumper {
    height: 65px;
  }

  .topbar-ham {
    display: block;
  }

  .is-mobileOpen {
    .nav--topbar {
      opacity: 1;
      pointer-events: all;
    }

    .cross-line {
      stroke-width: 4;
      transform: scale(1);
    }
    .nav-line,#nav-line-1,#nav-line-3 {
      transform: scale(0.01);
    }

    &:hover {
      .cross-line {
        transform: scale(1);
      }

      #nav-line-1,
      #nav-line-2,
      #nav-line-3 {
        stroke-width: 0;
      }
    }
  }
}
