.landingPage {
  position: relative;
  height: 100vh;
}
.landingPage--hasimage {
  .landingPage-cover {
    opacity: 0.25;
  }
}
.landingPage-backgroundImage {
  @extend %cover;
  background: $banner-background;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 150%;
  @include animation(landingPage-backgroundImage 45s linear infinite alternate-reverse);
  @include transform(translate3d(0,0,0));
}
.landingPage-cover {
  @extend %cover;
  @include linear-gradient(120deg, rgba($blue, 0.75) 0%, rgba($purple, 0.75) 90%);
}
.landingPage-container {
  @extend .l-container;
  @extend %vertical-align;
}
.landingPage-content {
  position: relative;
  z-index: $z-landingPage-content;
  max-width: 40rem;
}
.landingPage-branding {
  margin-bottom: $base-spacing*3.75;
}
.landingPage-branding .branding-link,
.landingPage-branding svg {
  height: 72px;
  width: 300px;
}
.landingPage-title {
  padding: 0;
  @extend .ms-5;
  font-weight: 200;
  color: $white;
  text-shadow: 0 1px 1px rgba($black, 0.1);
}
.landingPage-subtitle {
  padding: 0;
  @extend .ms-2;
  font-weight: 300;
  color: $white;
  text-shadow: 0 1px 1px rgba($black, 0.1);
}
.landingPage-action-input {
  margin-bottom: 0;
  margin-right: rem(10);
  padding: rem(13) rem(12) rem(11);
  background: transparent;
  color: $white;
  outline: none;
  border: 1px solid $grey-light;
  // font-weight: 600;
  font-size: 1rem;

  @include placeholder {
    color: $grey-dark;
  }

  &:focus {
    background: rgba($white, 0.1);
    border-color: $btn-primary-border;
  }
}
.response {
  color: $grey-light;
  padding: 1rem;
  margin-bottom: .5rem;
  background: rgba($white, 0.1);
}
.landingPage-formerly-anchor {
  color: $grey-dark;
  padding-bottom: 0.125rem;
  border-bottom: 1px solid $grey-dark;
  &:hover {
    color: $grey-light;
    border-bottom: 1px solid $grey-light;
  }
}

@include media($tablet) {
  .landingPage-action-input,
  .btn--landingPage {
    width: auto;
  }
  .landingPage-backgroundImage {
    @include animation( landingPage-backgroundImage 29.25s linear infinite alternate-reverse);
  }
}

@include media($mobile) {
  .landingPage-action-input {
    margin-bottom: rem(10);
  }
  .landingPage-action-input,
  .btn--landingPage {
    width: 100%;
  }
  .landingPage-backgroundImage {
    @include animation( landingPage-backgroundImage 18s linear infinite alternate-reverse);
  }
  .landingPage-branding {
    margin-bottom: $base-spacing*2.5;
  }
  .landingPage-branding .branding-link,
  .landingPage-branding svg {
    height: 36px;
    width: 200px;
  }
}
