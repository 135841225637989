
.form-titlebar {
  padding: 10px 0 30px;
}

.form-heading {
  margin: 0 0 10px;
  padding: 0;
  @include font(18);
}

.form-half {
  @include span-columns(6);
}

.field-set {
  @include outer-container();
}

.field {
  margin-bottom: 30px;
}

.field-checkbox {
  margin-bottom: $gutter/2 !important;

  .field-label {
    display: inline-block;
  }
}

.field-label {
  display: block;
  padding: 0 0;
  @include font(17, 600);
}

.field-helptext {
  display: block;
  padding: 8px 0 0;
  @include font(13, 400);
}

.field-typeSelect {
  select {
    @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
    @include font(13, 400);
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  }
}

.field-multiselect {
  select {
    width: 100%;
    outline: none;
    padding: 5px;
  }
}

.field-multiselect--large {
  select {
    height: 200px;
  }
}

.field-text {
  @include font(18);
  @include transition(box-shadow 0.25s);
  width: 100%;
  outline: none;
  background: #fff;
  padding: 5px;
  color: $base-heading;
  border: 1px solid $grey-light;
  box-shadow: none;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba($black, 0.1);

  &:focus {
    border-color: $blue-dark;
  }

  &:-webkit-autofill {
    box-shadow: inset 0 0 0 100px $white;
    color: $base-heading;

    &:focus {
      border-color: $blue-dark;
    }
  }
}

textarea{
  min-height: 150px;
}

.field-text--long {
  height: 100px;
}

.field--required {
  .field-label:after {
    content: "*";
    color: $red;
  }
}

.field--long {
  textarea {
    height: 150px;
  }
}

.field--error {
  .field-label {
    color: $red;
  }

  .field-text {
    border-color: $red;

    &:focus {
      border-color: $red;
      box-shadow: 0 2px $red;
    }
  }

  .alert--error{
    color: $red;
    padding: 0.75rem;
  }
}

.field--valid {
  .field-label {
  }

  .field-text {
    border-color: $green;
    box-shadow: 0 1px $green;

    &:focus {
      border-color: $blue-dark;
      box-shadow: 0 2px $blue-dark;
    }
  }
}

.form--large {
  .field-text {
    @include font(24, 500);
  }
}

@include media($mobile) {

  .field {
    margin-bottom: $gutter !important;

    &:last-child {
      margin-bottom: $gutter !important;
    }
  }

  .field-typeSelect {
    margin-bottom: 0;
  }
}

.formasp {
  margin-top: 50px;
  margin-bottom: 50px;
  @extend .l-tc;

  ul {
    list-style: none;
    margin: rem(-10) 0 rem(20) rem(10);
    padding: 0;
  }

  .errorlist {
    margin-top: rem(20);
    position: relative;
    color: $red;
    border: 1px solid $red;
    border-radius: 3px;
    background: rgba($red, 0.1);
    margin: 0;
    padding: rem(8) rem(15);

    &:first-child {
      margin-top: 0;
    }

    &:after {
      content: "";
      @include triangle(10px, $red, down);
      position: absolute;
      bottom: -6px;
      left: rem(10);
    }

    li {
      @include font(14, 400);
    }

    + p {
      label {
        color: $red;
      }
    }
  }

  p {
    margin: 0 0 rem(15);

    label {
      display: block;
      padding: rem(5) 0 0;
    }

    input {
      &[type="text"],
      &[type="email"],
      &[type="number"],
      &[type="url"] {
        @extend .field-text;
      }
    }

    textarea {
      @extend .field-text;
    }

    select {
      @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
      @include font(16, 400, 24px);
      display: block;
      height: 40px;
      padding: 6px 12px;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      width: 100%;
    }
  }
}
