.page {
  @include outer-container;
}

.page--centered {
  .page-main {
    @include span-columns(12);
    // @include shift(2);
    padding-right: 0;
  }
}

.page-main {
  @include span-columns(8);
  padding-right: 15px;
}

.page-side {
  @include span-columns(4);
  padding-left: 15px;
}

.page-heading {
  @extend .ms-3;
}

@include media($tablet) {

}

@include media($mobile) {
  .page--centered {
    .page-main {
      @include shift(0);
      @include span-columns(4);
    }
  }

  .page-main {
    @include span-columns(4);
  }

  .page-side {
    @include span-columns(4);
  }
}
