//-----------------------------------------------------------
// Z-INDEX
//-----------------------------------------------------------
// This file keeps track of all the z-index values throughout
// the app and provides an easy way for us to reference what
// sits where in relation to each other.


// Z-Index Scale (private vars)
//-----------------------------------------------------------
$zindex-1  : 100;
$zindex-2  : 200;
$zindex-3  : 300;
$zindex-4  : 400;
$zindex-5  : 500;
$zindex-6  : 600;
$zindex-7  : 700;
$zindex-8  : 800;
$zindex-9  : 900;
$zindex-10 : 1000; // Ideally, this level should never be used


// Z-Index Applications
//-----------------------------------------------------------

$z-header--full      : $zindex-1;
$z-content--page     : $zindex-2;

$z-banner-bg      : $zindex-1;
$z-banner-overlay : $zindex-2;
$z-banner-content : $zindex-3;

$z-header-bg      : $zindex-1;
$z-header-overlay : $zindex-2;
$z-header-content : $zindex-3;

$z-topbar         : $zindex-7;

$z-dropdown      : $zindex-5;
$z-dropdown-menu : $zindex-6;

$z-landingPage-content  : $zindex-1;
