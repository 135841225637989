.filters {
  text-align: center;
}

.filter-heading {
  @extend .ms-n1;
  @extend .ts-uppercase;
}

.filter-list {
  white-space: no-wrap;
}

.filter {
  margin: 0 2px;
  padding: 10px 20px;
  background: $white;
  display: inline-block;
  white-space: no-wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.is-active {
    background: $black-off;
    color: $white;
  }

  &:first-child {
    padding-left: 25px;
    @include border-left-radius(30px);
  }

  &:last-child {
    padding-right: 25px;
    @include border-right-radius(30px);
  }
}

@include media($tablet) {

}

@include media($mobile) {

}
