.findadvisor {
  @include display(flex);
}

.findadvisor-image {
  width: 50%;
  background: $white-off;
  margin: 0 0 50px;
  background-position: center;
  background-size: cover;
}

.findadvisor-content {
  position: relative;
  z-index: 2;
  background: $blue-dark;
  padding: rem(50);
  width: 50%;
  @include calc(width, "50% + 50px");
  margin: 50px 0 0 -50px;
}

.findadvisor-container {
  max-width: 470px;
}

.findadvisor-heading {
  @extend .ms-2;
  color: $white;
}

.findadvisor-text {
  p {
    color: rgba($white, 0.7);
  }
}

.findadvisor-more {
  color: $white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;

  &:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
    transform: rotate(-45deg);
    margin: 0 0 0 5px;
    top: -1px;
  }

  &:hover {
    color: $blue;

    &:after {
      border-right: 2px solid $blue;
      border-bottom: 2px solid $blue;
    }
  }
}

.findadvisor-action {
  text-align: right;
  margin-top: rem(20);
}

.findadvisor-image {

}


@include media($tablet) {

}

@include media($mobile) {
  .findadvisor {
    @include flex-direction(column);
  }


  .findadvisor-image {
    width: 95%;
    margin: 0 0 -50px;
    height: 40vh;
  }

  .findadvisor-content {
    background: $blue-dark;
    padding: rem(30);
    width: 95%;
    margin: 0 0 0 5%;
  }
}
