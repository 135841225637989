.gallery {
  overflow: hidden;
}

// .gallery-container {
//   position: relative;
//   margin: rem(30) 0;
// }

// .gallery-item {
//   position: relative;
//   display: inline-block;
//   background-color: $grey-light;
// }

// .gallery-item-caption {
//   @extend .ts-headingLight;
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1;
//   padding: 1em;
//   background-color: rgba($black, 0.5);
//   color: $white;
// }

// //
// // Slick-specific element styles.
// //
// .slick-slide {
//   position: relative;
//   padding: 0;
//   text-align: center;
//   outline: none;
// }

// $slick-prev-character:     "\2039" !default;
// $slick-next-character:     "\203a" !default;

// .slick-prev,
// .slick-next {
//   position: absolute;
//   top: 50%;
//   z-index: 10;
//   display: block;
//   width: 32px;
//   height: 32px;
//   padding: 0;
//   margin-top: -10px\9; /*lte IE 8*/
//   font-size: 0;
//   line-height: 0;
//   color: transparent;
//   cursor: pointer;
//   background-color: transparent;
//   border: 0;
//   outline: none;
//   -webkit-transform: translate(0, -50%);
//   -ms-transform: translate(0, -50%);
//   transform: translate(0, -50%);

//   &:hover,
//   &:focus {
//     outline: none;

//     &::before {
//       opacity: 0.75;
//     }
//   }

//   &::before {
//     display: block;
//     width: 32px;
//     height: 32px;
//     font-size: 36px;
//     line-height: 28px;
//     color: #d8d8d8;
//     background-color: $white;
//     border-radius: 50%;
//     opacity: 1;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }

//   &.slick-disabled {

//     &::before {
//       opacity: 0;
//     }
//   }
// }

// .slick-prev {
//   left: 15px;

//   [dir="rtl"] & {
//     right: 80px;
//     left: auto;
//   }

//   &::before {
//     padding-right: 2px;
//     content: $slick-prev-character;

//     [dir="rtl"] & {
//       padding-left: 2px;
//       content: $slick-next-character;
//     }
//   }
// }

// .slick-next {
//   right: 15px;

//   [dir="rtl"] & {
//     right: auto;
//     left: 80px;
//   }

//   &::before {
//     padding-left: 2px;
//     content: $slick-next-character;

//     [dir="rtl"] & {
//       padding-right: 2px;
//       content: $slick-prev-character;
//     }
//   }
// }

.slick-dots {

  li {

    button {
      width: 14px;
      height: 14px;
      border-radius: 20px;
      background-color: $white-offdark;

      &:before {
        display: none;
      }
    }

    &.slick-active button {
      background-color: $blue-dark;
    }
  }
}

// @include media($tablet) {

// }

// @include media($mobile) {

// }
