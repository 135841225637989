.banner {
  position: relative;
  text-align: center;
  background-image: url(../images/bg-banner.jpg);
  background-color: $banner-background;
  background-size: cover;
  background-position: center bottom;
  margin-bottom: 40px;
}

.banner--home {
  margin-bottom: -80px;
  // background: $banner-background !important; // TO FIX viDeo
}

.banner--error {
  .banner-content {
    padding-bottom: 0;
  }
}

.banner--home,
.banner--large {
  height: 85vh;
}

.banner--hasimage {
  background-position: center;

  .banner-cover {
    opacity: 0.1;
  }
}

.banner--medium {
  height: 55vh;
}

.banner-cover {
  opacity: 0;
  @extend %cover;
  @include linear-gradient(120deg, $blue 0%, $purple 90%);
}

.banner-container {
  @extend .l-container;
}

.banner--default {
  text-align: left;

  .banner-content {
    padding: rem(80) 0;
    max-width: 700px;
  }
}

.banner--inner {
  margin: 0 0 50px;

  &.is-image {
    margin: 0 0 110px;
  }

  .banner-container {
    padding-top: 120px;
    display: flex;
  }

  .banner-content {
    width: 59%;
    // padding: 50px 0;
    padding: 120px 60px 80px 0;
    text-align: left;
  }

  .banner-image {
    position: absolute;
    left: 56%;
    bottom: -90px;
    right: 0;
    top: 150px;
    overflow: hidden;
    border-radius: 3px 0 0 3px;
    box-shadow: 0 10px 20px rgba(#3D5366, 0.3);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .banner-title {
    @extend .ms-5;
    margin-bottom: 10px;
  }

  .banner-desc {
    margin-top: 5px;
    font-size: 150%;
  }
}

.banner-video {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.banner-container--home {
  padding-top: 120px;
  @extend %vertical-align;
  z-index: 2;
  text-align: left;

  .banner-content {
    padding: rem(80) 0;
    max-width: 450px;
  }

  .banner-title {
    @extend .ms-5;
    margin: 0 0 rem(10);
  }

  .banner-cover {
    opacity: 0;
  }

  .banner-action .btn {
    &:hover {
      color: $white;
      background: $blue-dark;
      border-color: $blue-dark;
    }
  }
}

.banner--large,
.banner--medium {
  .banner-container {
    text-align: left;
    height: 100%;
    @include display(flex);
    @include align-items(center);
  }
}

.banner-content {
  position: relative;
  padding: rem(160) 0 rem(40);
  z-index: $z-banner-content;
}

.banner-title {
  margin: 0;
  padding: 0;
  @extend .ms-5;
  font-weight: 200;
  color: $white;
  text-shadow: 0 1px 1px rgba($black, 0.25);
}

.banner-subtitle {
  margin: 0;
  padding: 0;
  @extend .ms-2;
  font-weight: 300;
  color: $white;
  text-shadow: 0 1px 1px rgba($black, 0.25);
}

.banner-desc {
  margin-top: rem(20);
  color: $white;
  @include font(20);
  text-shadow: 0 1px 1px rgba($black, 0.25);

  p {
    @extend .banner-subtitle;
  }
}

.banner-action {
  display: block;
  padding-top: rem(20);

  .btn {
    text-shadow: 0 1px 1px rgba($black, 0.1);
    box-shadow: 0 1px 1px rgba($black, 0.1);

    &:hover {
      text-shadow: none
    }
  }
}

@include media($tablet) {
  .banner--home {
    margin-bottom: -50px;
  }

  .banner-container--home,
  .banner-container--large,
  .banner-container--medium {
    .banner-content {
      max-width: 400px;
    }
  }
}

@include media($mobile) {
  .banner--home {
    margin-bottom: -30px;
  }

  .banner-container--home,
  .banner-container--large,
  .banner-container--medium {
    text-align: center;
    padding-top: 80px;

    .banner-content {
      padding: rem(40) 0;
      width: 100%;
    }

    .banner-title {
      @extend .ms-5;
      margin: 0 0 rem(10);
    }
  }

  .banner-content {
    padding: rem(40) 0;
    padding: rem(120) 0 rem(40) 0;
  }

  .banner-title {
    font-size: 38px;
  }

  .banner-video {
    display: none;
  }

  .banner--inner {
    margin: 0 0 50px;

    &.is-image {
      margin: 0 0 110px;
    }

    .banner-container {
      padding-top: 70px;
      display: flex;
      flex-direction: column;
    }

    .banner-content {
      width: 100%;
      margin: 20px 0;
      padding: 0;
      text-align: left;
    }

    .banner-image {
      margin: 20px 0 -30px;
      position: static;
      width: 100%;
      overflow: hidden;
      border-radius: 3px 0 0 3px;
      box-shadow: 0 10px 20px rgba(#3D5366, 0.3);

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .banner-title {
      @extend .ms-3;
    }

    .banner-desc {
      margin-top: 5px;
      font-size: 120%;
    }
  }
}
