.columns {
  display: flex;
  flex-wrap: wrap;
}

.column {
  padding: 10px;
  box-sizing: border-box;
}

.column--left {
  flex: 1 1 50%;
}

.column--right {
  flex: 1 1 50%;
}

@media (max-width: 1024px) {
  .column {
    padding: 0;
  }

  .column--left,
  .column--right {
    flex: 1 0 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
