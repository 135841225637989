.cta {
  text-align: center;
}

.cta--card {
  padding: 30px;
  border: 1px solid $grey-light;
}

.cta-heading {
  @extend .ms-3;
}

.cta-actions {
  padding-top: rem(10);
}

@include media($tablet) {

}

@include media($mobile) {

}
