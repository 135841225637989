.emphasis {
  &.wrap-right {
    width: 40%;
    float: right;
    margin: 30px 0% 30px 30px;
  }

  &.wrap-left {
    width: 40%;
    float: left;
    margin: 30px 30px 30px 0%;
  }

  &.full {
    margin: 30px 0% 30px 0%;

    .emphasis-text {
      @include font(38, 300, 1.4, $base-font-heading);
    }
  }
}

.emphasis-text {
  margin: 0 0 rem(10);
  padding: 0;
  @include font(22, 300, 1.4, $base-font-heading);
  color: $base-heading;
}

.emphasis-subtext {
  margin: 0;
  padding: 0;
  @include font(16, 400, 1.2);
  font-style: italic;
  color: $base-type-light;
}

@include media($tablet) {

}

@include media($mobile) {
  .emphasis {
    &.wrap-right,
    &.wrap-left,
    &.full {
      float: none;
      width: 100%;
      margin: 20px 0;
      padding: 0 15px;
    }
  }

  .emphasis-text {
    @include font(22, 300, 1.4, $base-font-heading);
  }

  .emphasis-subtext {
    @include font(16, 400, 1.2);
  }
}
